import {Nav, Navbar, NavbarBrand, Dropdown, Tooltip, OverlayTrigger, Button} from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import {FaHome, FaUserLock, FaUser, FaShoppingCart, FaRegArrowAltCircleLeft} from 'react-icons/fa';

import Searchbar from "./Searchbar";
import CustomNavLink from "./CustomNavLink";
import CustomNavDropown from "./CustomNavDropdown";

export default function CustomNav ({isLoggedIn, role, onLogout, toggleSearchbar, searchbarActive,
                                       updateFilters, searchbarText, winkelWagen, username}) {
    let history = useHistory();

    async function logout() {
        const res = await fetch('/php/logout.php');
        const data = await res.json();

        if (data['ok']) {
            onLogout();
        }
    }

    // Redirect functions
    function historyBack() {
        if (history.length < 2) return;
        history.goBack();
    }

    const collapse = "md";
    return (
        <>
            <Navbar activeKey="/" className="bg-light p-2 container-fluid" expand={collapse}>
                <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    overlay={
                        <Tooltip className={"d-none d-"+collapse+"-block"}>
                            Go back
                        </Tooltip>
                    }
                >
                    <Nav>
                        <Nav.Link as={Link} onClick={historyBack} className="px-2">
                            <FaRegArrowAltCircleLeft size="1.8em"/>
                        </Nav.Link>
                    </Nav>
                </OverlayTrigger>
                <NavbarBrand to="/" as={Link} className="d-flex align-items-center">
                    <img src="./images/logo_tballonneke_klein.svg" width="48" height="48" alt="Logo"/>
                    <span className="jt-nav-title d-none d-sm-inline">Speelotheek</span>
                </NavbarBrand>
                <Searchbar
                    isLoggedIn={isLoggedIn}
                    toggleSearchbar={toggleSearchbar}
                    searchbarActive={searchbarActive}
                    updateFilters={updateFilters}
                    searchbarText={searchbarText}
                />
                <Navbar.Toggle aria-controls="navbar-links" className="border-0"/>
                <Navbar.Collapse className="jt-nav-collapse" id="navbar-links">
                    <Nav>
                        {isLoggedIn
                            ?
                            <>
                                <Nav.Item className="d-flex align-items-center">
                                    <CustomNavLink
                                        title="Home"
                                        faIcon={<FaHome size="1.8em"/>}
                                        collapse={collapse}
                                        to="/"
                                    />
                                </Nav.Item>
                                {role === "Admin"
                                    ?
                                    <CustomNavDropown
                                        title="Admin"
                                        faIcon={<FaUserLock size="1.8em"/>}
                                        collapse={collapse}
                                        dropdownMenu={
                                            <Dropdown.Menu className="bg-light dropdown-menu-right">
                                                <Dropdown.Item to="/admin/speelgoed" as={Link} className="bg-light text-dark">Speelgoed</Dropdown.Item>
                                                <Dropdown.Item to="/admin/reservaties" as={Link} className="bg-light text-dark">Reservaties</Dropdown.Item>
                                                <Dropdown.Item to="/admin/gebruikers" as={Link} className="bg-light text-dark">Gebruikers</Dropdown.Item>
                                                <Dropdown.Item to="/admin/categorieen" as={Link} className="bg-light text-dark">Categorieën</Dropdown.Item>
                                                <Dropdown.Item to="/admin/periodes" as={Link} className="bg-light text-dark">Periodes</Dropdown.Item>
                                                <Dropdown.Item to="/admin/reviews" as={Link} className="bg-light text-dark">Reviews</Dropdown.Item>
                                                <Dropdown.Item to="/admin/doelgroepen" as={Link} className="bg-light text-dark">Doelgroepen</Dropdown.Item>
                                                <Dropdown.Item to="/admin/statistiek" as={Link} className="bg-light text-dark">Statistiek</Dropdown.Item>
                                            </Dropdown.Menu>
                                        }
                                    />
                                    :
                                    <></>
                                }
                                <Nav.Item className="d-flex align-items-center">
                                    <CustomNavLink
                                        title="Winkelkar"
                                        faIcon={<FaShoppingCart size="1.8em"/>}
                                        collapse={collapse}
                                        to="/winkelwagen"
                                        shoppingCartLength={winkelWagen.length}
                                    />
                                </Nav.Item>
                                <CustomNavDropown
                                    title={username}
                                    faIcon={<FaUser size="1.8em"/>}
                                    collapse={collapse}
                                    dropdownMenu={
                                        <Dropdown.Menu className="bg-light dropdown-menu-right">
                                            <Dropdown.Item to="/mijnreservaties" as={Link} className="bg-light text-dark">Mijn reservaties</Dropdown.Item>
                                            <Dropdown.Item onClick={logout} className="bg-light text-dark">Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    }

                                />
                            </>
                            :
                            <>
                                <Nav.Item className="d-flex align-items-center">
                                    <Nav.Link to="/login" as={Link}>Login</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="d-flex align-items-center">
                                    <Nav.Link to="/sign_up" as={Link}>Registreer</Nav.Link>
                                </Nav.Item>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}