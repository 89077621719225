import CustomNav from "./CustomNav";
import SearchbarUnder from './SearchbarUnder'
import {useState} from "react";

export default function Header ({isLoggedIn, role, onLogout, updateFilters, searchbarText, winkelWagen, username}) {
    const [showSearchbar, setShowSearchbar] = useState(false);

    function toggleSearchbar() {
        setShowSearchbar(!showSearchbar);
        updateFilters("search", "", false);
    }

    return (
        <header className="jt-no-print">
            <CustomNav
                isLoggedIn={isLoggedIn}
                role={role}
                onLogout={onLogout}
                toggleSearchbar={toggleSearchbar}
                searchbarActive={showSearchbar}
                updateFilters={updateFilters}
                searchbarText={searchbarText}
                winkelWagen={winkelWagen}
                username={username}
            />
            {showSearchbar
                ?
                <SearchbarUnder
                    isLoggedIn={isLoggedIn}
                    updateFilters={updateFilters}
                    searchbarText={searchbarText}
                />
                :
                <></>
            }
        </header>
    )
}