import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";

export default function SearchSelect({selectList, onSelect, placeholder, initialValue}) {
    const [focus, setFocus] = useState(false);
    const [text, setText] = useState("");
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        onChangeInput(initialValue);
    }, []);

    function onChangeInput(value) {
        setText(value);
        if (placeholder === "Speelgoed") {
            setFilteredList(selectList.filter(item => (item['naam'].toLowerCase().includes(value.toLowerCase()) || item['oudCode'].toLowerCase().includes(value.toLowerCase()))));
        } else {
            setFilteredList(selectList.filter(item => (item['naam'].toLowerCase().includes(value.toLowerCase()))));
        }
    }

    function selectSpeelgoed(id, naam) {
        onChangeInput(naam);
        setFocus(false);
        onSelect(id);
    }

    return (
        <div
            className="position-relative"
            onFocus={() => setFocus(true)}
        >
            <Form.Control
                type="text"
                placeholder={placeholder}
                value={text}
                onChange={e => onChangeInput(e.target.value)}
                required
            />
            {/*Options*/}
            <div
                className={focus ? "d-flex flex-column position-absolute p-2 bg-achtergrondkleur jt-front w-100 shadow" : "d-none"}
            >
                {filteredList
                    .slice(0, 6)
                    .map(item => (
                    placeholder === "Speelgoed"
                    ?
                        <div
                            onClick={() => selectSpeelgoed(item['id'], item['naam'])}
                            className="jt-clickable my-2 p-2 bg-filter"
                        >
                            <Row>
                                <Col xs="2">
                                    <img
                                        src={item['foto'] ? item['foto'] : "/images/placeholder.jpg"}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </Col>
                                <Col xs="10" className="d-flex flex-row align-items-center">
                                {item['naam']} ({item['oudCode']})
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="d-flex flex-row align-items-center pt-2">
                                    {!item['beschikbaar']
                                        ?
                                        <Badge pill variant="danger" className="mr-2">
                                        <span className="mr-2">
                                            Niet beschikbaar
                                        </span>
                                        </Badge>
                                        :
                                        <></>
                                    }
                                    {item['specialeAanvraag']
                                        ?
                                        <Badge pill variant="primary" className="mr-2">
                                        <span className="mr-2">
                                            Speciale aanvraag
                                        </span>
                                        </Badge>
                                        :
                                        <></>
                                    }
                                </Col>
                            </Row>
                        </div>
                    :
                        <div
                            onClick={() => selectSpeelgoed(item['id'], item['naam'])}
                            className="jt-clickable my-2 p-2 bg-filter"
                        >
                            <Row>
                                <Col xs="12" className="d-flex flex-row align-items-center">
                                    {item['naam']}
                                </Col>
                            </Row>
                        </div>
                ))}
            </div>
        </div>
    )
}

SearchSelect.defaultProps = {
    initialValue: ""
}