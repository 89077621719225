import {Badge, Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FaArchive, FaPen, FaPlus, FaTimes, FaTrashAlt} from "react-icons/fa";
import {useState} from "react";
import {sha256} from "js-sha256";
import {Redirect} from "react-router-dom";

export default function AdminGebruikersPage({isLoggedIn, gebruikers, onAddGebruiker, onEditGebruiker, onDeleteGebruiker, userData}) {
    const [showArchief, setShowArchief] = useState(0);
    const [addGebruiker, setAddGebruiker] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [gebruikersChecked, setGebruikersChecked] = useState([]);

    const [currentEditedGebruiker, setCurrentEditedGebruiker] = useState({"id": -1});
    const [changePassword, setChangePassword] = useState(false);

    const [nieuweGebruiker, setNieuweGebruiker] = useState({"naam": "", "email": "", "wachtwoord": ""});

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionId, setActionId] = useState(-1);

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    function onHideModalAdd() {
        setAddGebruiker(false);
        setNieuweGebruiker({"naam": "", "email": "", "wachtwoord": ""});
    }

    function onHideModalEdit() {
        setCurrentEditedGebruiker({"id": -1});
        setChangePassword(false);
    }

    function onHideModalDelete() {
        setShowDeleteConfirm(false);
        setActionInProgress("");
        setActionId(-1);
    }

    function toggleAllChecked() {
        if (allChecked) {
            setGebruikersChecked([]);
            setAllChecked(false);
        } else {
            setGebruikersChecked(gebruikers
                .filter(item => (showArchief === -1) || (showArchief === 0 && !item['isArchief']) || (showArchief === 1 && item['isArchief']))
                .filter(item => (item['doelgroepId'] === userData['sessDoelgroep']))
                .map(item => item['id'])
            );
            setAllChecked(true);
        }
    }

    function togglePeriodeChecked(periodeId) {
        setAllChecked(false);
        if (gebruikersChecked.includes(periodeId)) {
            setGebruikersChecked(gebruikersChecked.filter((id) => (id !== periodeId)))
        } else {
            setGebruikersChecked([...gebruikersChecked, periodeId]);
        }
    }

    function toggleEditedGebruiker(gebruikerId) {
        if (currentEditedGebruiker['id'] === gebruikerId) {
            onHideModalEdit();
        } else {
            setCurrentEditedGebruiker(gebruikers.find(item => (item['id'] === gebruikerId)));
        }
    }

    async function addGebruikerRemote(e) {
        e.preventDefault();
        setActionInProgress("Maak aan");

        let gebruiker = {"naam": jsonEscape(nieuweGebruiker['naam']), "email": jsonEscape(nieuweGebruiker['email']), "wachtwoord": sha256(nieuweGebruiker['wachtwoord'])};

        const res = await fetch('/php/admin/gebruikerAdd.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(gebruiker)
        });
        const data = await res.json();

        if (data['ok']) {
            onAddGebruiker(data['gebruiker']);
        } else {
            alert('Het aanmaken van een gebruiker is mislukt');
        }
        onHideModalAdd();
        setActionInProgress("");
    }

    async function editGebruikerRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");

        let gebruiker = {...currentEditedGebruiker, "naam": jsonEscape(currentEditedGebruiker['naam']), "email": jsonEscape(currentEditedGebruiker['email']), "wachtwoord": (changePassword ? sha256(currentEditedGebruiker['wachtwoord']) : 0)};

        const res = await fetch('/php/admin/gebruikerEdit.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(gebruiker)
        });
        const data = await res.json();

        if (data['ok']) {
            onEditGebruiker(data['gebruiker']);
        } else {
            alert('Het bewerken van een gebruiker is mislukt');
        }
        onHideModalEdit();
        setActionInProgress("");
    }

    async function editRoleRemote(gebruikerId, newRole) {
        const res = await fetch('/php/admin/gebruikerEditRole.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                gebruikerId,
                "role": newRole
            })
        });
        const data = await res.json();

        if (data['ok']) {
            let gebruiker = {...gebruikers.find(item => (item['id'] === data['id'])), "role": data['role']}
            onEditGebruiker(gebruiker);
        } else {
            alert('Het veranderen van de rol van de gebruiker is mislukt');
        }
    }

    async function toggleArchiveGebruikerRemote(gebruikerId) {
        setActionId(gebruikerId);
        setActionInProgress("Archiveren");
        const res = await fetch('/php/admin/gebruikerArchive.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                gebruikerId,
                "isArchief": !(gebruikers.find(item => (item['id'] === gebruikerId))['isArchief'])
            })
        });
        const data = await res.json();

        if (data['ok']) {
            let gebruiker = {...gebruikers.find(item => (item['id'] === data['id'])), "isArchief": data['isArchief']}
            onEditGebruiker(gebruiker);
        } else {
            alert('Het archiveren van een gebruiker is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    function deleteGebruikerLocal(id) {
        setActionId(id);
        setShowDeleteConfirm(true);
        let ids = [];
        if (id === -1) {
            ids = gebruikersChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    async function deleteGebruikerRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/gebruikerDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeleteGebruiker(data['ids']);
        } else {
            alert('Het verwijderen is mislukt');
        }
        onHideModalDelete();
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Admin Gebruikers</h1>
                <Row>
                    <Form.Group className="mb-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Archief:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={showArchief}
                                onChange={e => setShowArchief(parseInt(e.target.value))}
                            >
                                <option value="-1">Toon alles</option>
                                <option value="0">Verberg archief</option>
                                <option value="1">Toon archief</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Row>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th/>
                        <th>Naam</th>
                        <th>Email</th>
                        <th>Rol</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={() => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={3}/>
                        <td className="jt-table-actions">
                            <Button variant="success" className="rounded-circle" onClick={() => setAddGebruiker(!addGebruiker)}>{addGebruiker ? <FaTimes/> : <FaPlus/>}</Button>
                            <Button variant="danger" className="rounded-circle" onClick={() => deleteGebruikerLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {gebruikers
                        .filter(item => (showArchief === -1) || (showArchief === 0 && !item['isArchief']) || (showArchief === 1 && item['isArchief']))
                        .filter(item => ((item['doelgroepId'] === userData['sessDoelgroep'])))
                        .map(gebruiker => (
                        <tr>
                            <td className="jt-table-id">
                                <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                    <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="jt-table-check"
                                            onChange={() => togglePeriodeChecked(gebruiker['id'])}
                                            checked={gebruikersChecked.includes(gebruiker['id'])}
                                        />
                                    </Form>
                                </div>
                            </td>
                            <td>
                                <span className="mr-2">
                                    {gebruiker['naam']}
                                </span>
                                {gebruiker['isArchief']
                                    ?
                                    <Badge pill variant="archive" className="mr-2">
                                                <span className="mr-2">
                                                    Gearchiveerd
                                                </span>
                                        <FaTimes as={Button}
                                                 onClick={() => toggleArchiveGebruikerRemote(gebruiker['id'])}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                }
                            </td>
                            <td>
                                {gebruiker['email']}
                            </td>
                            <td>
                                <Form.Control
                                    as="select"
                                    value={gebruiker['role']}
                                    onChange={(e) => editRoleRemote(gebruiker['id'], e.target.value)}
                                >
                                    <option value="Admin">Admin</option>
                                    <option value="Gebruiker">Gebruiker</option>
                                    <option value="Niet bevestigd">Niet bevestigd</option>
                                </Form.Control>
                            </td>
                            <td className="jt-table-actions">
                                <Button variant="info" className="rounded-circle" onClick={() => toggleEditedGebruiker(gebruiker['id'])}>{gebruiker['id'] === currentEditedGebruiker ? <FaTimes/> : <FaPen/>}</Button>
                                {actionInProgress === "Archiveren" && actionId === gebruiker['id']
                                    ?
                                    <Button variant="archive" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant="archive" className="rounded-circle" onClick={() => toggleArchiveGebruikerRemote(gebruiker['id'])}><FaArchive/></Button>
                                }
                                <Button variant="danger" className="rounded-circle" onClick={() => deleteGebruikerLocal(gebruiker['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={addGebruiker} onHide={() => onHideModalAdd()}>
                <Modal.Header closeButton>
                    <Modal.Title>Nieuwe gebruiker:</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addGebruikerRemote}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Naam:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Gebruikersnaam"
                                value={nieuweGebruiker['naam']}
                                onChange={(e) => setNieuweGebruiker({...nieuweGebruiker, "naam": e.target.value})}
                                required
                            >

                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Emailadres"
                                value={nieuweGebruiker['email']}
                                onChange={(e) => setNieuweGebruiker({...nieuweGebruiker, "email": e.target.value})}
                                required
                            >

                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Wachtwoord:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Wachtwoord"
                                value={nieuweGebruiker['wachtwoord']}
                                onChange={(e) => setNieuweGebruiker({...nieuweGebruiker, "wachtwoord": e.target.value})}
                                required
                            >

                            </Form.Control>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModalAdd()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Maak aan"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Maak aan
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={currentEditedGebruiker['id'] !== -1} onHide={() => onHideModalEdit()}>
                <Modal.Header closeButton>
                    <Modal.Title>Bewerk gebruiker:</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editGebruikerRemote}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Naam:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Gebruikersnaam"
                                value={currentEditedGebruiker['naam']}
                                onChange={(e) => setCurrentEditedGebruiker({...currentEditedGebruiker, "naam": e.target.value})}
                                required
                            >

                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Emailadres"
                                value={currentEditedGebruiker['email']}
                                onChange={(e) => setCurrentEditedGebruiker({...currentEditedGebruiker, "email": e.target.value})}
                                required
                            >

                            </Form.Control>
                        </Form.Group>

                        <Form.Check
                            type="switch"
                            id="switch"
                            value={changePassword}
                            onChange={() => setChangePassword(!changePassword)}
                            label="Wachtwoord veranderen"
                        />

                        {changePassword
                            ?
                            <Form.Group>
                                <Form.Label>Wachtwoord:</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Wachtwoord"
                                    value={currentEditedGebruiker['wachtwoord']}
                                    onChange={(e) => setCurrentEditedGebruiker({...currentEditedGebruiker, "wachtwoord": e.target.value})}
                                    required
                                >

                                </Form.Control>
                            </Form.Group>
                            :
                            <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModalEdit()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Bewerk"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Bewerk
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDeleteConfirm} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder gebruiker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} gebruiker{toDelete.length > 1 ? "s" : ""} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deleteGebruikerRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}