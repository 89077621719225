import {Button, Form, Spinner, Table} from "react-bootstrap";
import {FaTrash, FaTrashAlt} from "react-icons/fa";
import {useState} from "react";
import {Link, Redirect} from "react-router-dom";

export default function WinkelWagenPage({isLoggedIn, periodes, winkelWagen, onWinkelWagenDelete, speelgoed,
                                            onWinkelWagenEdit, onWinkelWagenCommit, reservaties, formatDate}) {
    const [allChecked, setAllChecked] = useState(false);
    const [itemsChecked, setItemsChecked] = useState([]);

    const [committing, setCommitting] = useState(false);

    function toggleAllChecked() {
        if (allChecked) {
            setItemsChecked([]);
            setAllChecked(false);
        } else {
            setItemsChecked(winkelWagen.map(item => item['id']));
            setAllChecked(true);
        }
    }

    function toggleItemsChecked(itemId) {
        setAllChecked(false);
        if (itemsChecked.includes(itemId)) {
            setItemsChecked(itemsChecked.filter((id) => (id !== itemId)))
        } else {
            setItemsChecked([...itemsChecked, itemId]);
        }
    }

    function onWinkelWagenDeleteLocal(id) {
        if(id === -1) {
            onWinkelWagenDelete(itemsChecked);
            setItemsChecked([]);
            setAllChecked(false);
        } else {
            onWinkelWagenDelete([id]);
            setItemsChecked(itemsChecked.filter(item => !(item['id'] === id)));
        }
    }

    async function onWinkelWagenCommitLocal() {
        setCommitting(true);
        const res = await fetch('/php/confirmShoppingCart.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({winkelWagen})
        });
        const data = await res.json();

        if (data['ok']) {
            onWinkelWagenCommit(data);
            setCommitting(false);
        } else {
            alert("Er was een error bij het uploaden van de reservaties");
            setCommitting(false);
        }
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Winkelwagen</h1>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th/>
                        <th>Speelgoed</th>
                        <th>Periode</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={() => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={2}/>
                        <td className="jt-table-actions">
                            <Button variant="danger" className="rounded-circle" onClick={() => onWinkelWagenDeleteLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {winkelWagen.map(item => (
                        <tr>
                            <td className="jt-table-id">
                                <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                    <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="jt-table-check"
                                            onChange={() => toggleItemsChecked(item['id'])}
                                            checked={itemsChecked.includes(item['id'])}
                                        />
                                    </Form>
                                </div>
                            </td>
                            <td>
                                {speelgoed.filter(spg => (item['speelgoedId'] === spg['id'])).map(item => (
                                    <Link to={"/speelgoedfiche?id=" + item['id']} id={item['id'] + "-naam"}>
                                        <img src={item['foto'] ? item['foto'] : "/images/placeholder.jpg"} className="jt-table-img" alt=""/>
                                        <span className="jt-table-naam">{item['naam']}</span>
                                    </Link>
                                ))}
                            </td>
                            <td>
                                <Form.Control
                                    id="periode"
                                    as="select"
                                    value={item['periodeId']}
                                    onChange={(e) => onWinkelWagenEdit(item['id'], e.target.value)}
                                >
                                    {periodes
                                        .filter(periode => !(
                                            periode['isArchief'] ||
                                            winkelWagen.filter(wwItem => (wwItem['id'] !== item['id'] && wwItem['speelgoedId'] === item['speelgoedId']))
                                                .map(wwItem => wwItem['periodeId'])
                                                .includes(periode['id']) ||
                                            reservaties.filter(res => (!res['isArchief'] && res['speelgoedId'] === item['speelgoedId']))
                                                .map(res => res['periodeId'])
                                                .includes(periode['id'])
                                        ))
                                        .map((periode) => (
                                        <option
                                            value={periode['id']}
                                        >
                                            {"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}
                                        </option>
                                    ))}
                                </Form.Control>
                            </td>
                            <td className="jt-table-actions">
                                <Button variant="danger" className="rounded-circle" onClick={() => onWinkelWagenDeleteLocal(item['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <td colSpan={4} className="text-right">
                            <Button
                                as={Link}
                                to="/"
                                className="mr-2"
                                variant="secondary"
                            >
                                Verder Winkelen
                            </Button>
                            {!winkelWagen.length
                                ?
                                <Button
                                    variant={winkelWagen.length > 0 ? "success" : "danger"}
                                    onClick={() => onWinkelWagenCommitLocal()}
                                    className="m-2"
                                    disabled={!winkelWagen.length}
                                >
                                    {winkelWagen.length > 0 ? (committing
                                            ?
                                            <>
                                                <Spinner animation="border" role="status" variant="light">
                                                    <span className="sr-only">Laden...</span>
                                                </Spinner>
                                                <span className="ml-2">
                                            Laden...
                                        </span>
                                            </>
                                            : "Bevestig"
                                    ) : "Winkelwagen is leeg"}
                                </Button>
                                :
                                <Button
                                    as={Link}
                                    to="/winkelwagenBevestigd"
                                    variant={winkelWagen.length > 0 ? "success" : "danger"}
                                    onClick={() => onWinkelWagenCommitLocal()}
                                    className="m-2"
                                    disabled={!winkelWagen.length}
                                >
                                    {winkelWagen.length > 0 ? (committing
                                            ?
                                            <>
                                                <Spinner animation="border" role="status" variant="light">
                                                    <span className="sr-only">Laden...</span>
                                                </Spinner>
                                                <span className="ml-2">
                                            Laden...
                                        </span>
                                            </>
                                            : "Bevestig"
                                    ) : "Winkelwagen is leeg"}
                                </Button>
                            }
                        </td>
                    </tfoot>
                </Table>
            </main>
        </>
    )
}