import {Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {FaArchive, FaPen, FaPlus, FaTimes, FaTrashAlt} from "react-icons/fa";
import {useEffect, useState} from "react";

export default function CategorieTable ({ categorie, onAddSubCategorie, onEditCategorie, onEditSubCategorie, onShowDeleteModal, removeChecked, resetRemoveChecked }) {
    const [subCategorieInEditMode, setSubCategorieInEditMode] = useState();
    const [categorieInEditMode, setCategorieInEditMode] = useState(false);
    const [showBody, setShowBody] = useState(false);

    const [subCategorieNaam, setSubCategorieNaam] = useState();
    const [checked, setChecked] = useState([]);
    const [categorieChecked, setCategorieChecked] = useState(false);

    const [editedCategorieNaam, setEditedCategorieNaam] = useState(categorie['naam']);
    const [editedSubCategorieNaam, setEditedSubCategorieNaam] = useState();

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionOnId, setActionOnId] = useState(-1);

    useEffect(() => {
        if (removeChecked === categorie['id']){
            setChecked([]);
            resetRemoveChecked();
        }
    }, [removeChecked]);

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    function toggleBody() {
        setShowBody(!showBody);
    }

    function toggleCategorieEditMode() {
        setCategorieInEditMode(!categorieInEditMode);
        setSubCategorieInEditMode(0);
    }

    function toggleSubCategorieEditMode(subCategorieId) {
        if (subCategorieInEditMode == subCategorieId)
            return setSubCategorieInEditMode(0);
        setSubCategorieInEditMode(subCategorieId);
        setEditedSubCategorieNaam(categorie['subCategorieen'].filter((sc) => (sc['id'] == subCategorieId))[0]['naam']);
        setCategorieInEditMode(false);
    }

    function toggleCategorieChecked() {
        if (categorieChecked) {
            setChecked([]);
            setCategorieChecked(false);
        } else {
            setChecked(categorie['subCategorieen'].map((sc) => (sc['id'])));
            setCategorieChecked(true);
        }
    }

    function toggleSubCategorieChecked(scId) {
        setCategorieChecked(false);
        if (checked.includes(scId)) {
            setChecked(checked.filter((id) => (id != scId)))
        } else {
            setChecked([...checked, scId]);
        }
    }

    async function addSubCategorieRemote(e) {
        e.preventDefault();
        setActionInProgress("Maak aan");

        const res = await fetch('/php/admin/addSubCategorie.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"categorieId": categorie['id'], "subCategorieNaam": jsonEscape(subCategorieNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onAddSubCategorie(data['subCategorie'], categorie);
            setSubCategorieNaam('');
        } else {
            alert('Het aanmaken van een nieuwe subcategorie is mislukt');
        }
        setActionInProgress("");
    }

    async function editCategorieRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");

        const res = await fetch('/php/admin/editCategorie.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"categorieId": categorie['id'], "editedCategorieNaam": jsonEscape(editedCategorieNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onEditCategorie(data['newCategorieNaam'], categorie);
            setCategorieInEditMode(false);
        } else {
            alert('Het bewerken van de categorie is mislukt');
        }
        setActionInProgress("");
    }

    async function editSubCategorieRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");
        setActionOnId(subCategorieInEditMode);

        const res = await fetch('/php/admin/editSubCategorie.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"subCategorieId": subCategorieInEditMode, "editedSubCategorieNaam": jsonEscape(editedSubCategorieNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onEditSubCategorie(data['newSubCategorieNaam'], categorie, subCategorieInEditMode);
            setSubCategorieInEditMode(0);
        } else {
            alert('Het bewerken van de subcategorie is mislukt');
        }

        setActionOnId(-1);
        setActionInProgress("");
    }

    function removeSubCategorieLocal(subCategorieId) {
        let subCategorieIds = [];
        let removeCategorie = false
        if (subCategorieId === -1) {
            if (categorie['naam'] === "Leeftijd") {
                subCategorieIds = checked;
            } else {
                if (checked.length === 0) {
                    removeCategorie = true;
                    subCategorieIds = categorie['subCategorieen'].map((sc) => (sc['id']));
                } else {
                    removeCategorie = categorieChecked;
                    subCategorieIds = checked;
                }
            }
        } else {
            subCategorieIds.push(subCategorieId);
        }
        onShowDeleteModal(subCategorieIds, removeCategorie, categorie);
    }

    return (
        <Table striped bordered hover size="sm" className="jt-table">
            <thead>
            <tr>
                <th className="jt-table-vertical-center">
                    {categorieInEditMode
                    ?
                        <div>
                            <Form onSubmit={editCategorieRemote}>
                                <Form.Group className="d-flex flex-row my-2">
                                    <Col xs={10}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Categorie naam"
                                            value={editedCategorieNaam}
                                            onChange={(e) => setEditedCategorieNaam(e.target.value)}
                                            required
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        {actionInProgress === "Bewerk" && actionOnId === -1
                                            ?
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> Laden...
                                            </Button>
                                            :
                                            <Button variant="primary" type="submit" className="form-control">
                                                Bewerk
                                            </Button>
                                        }
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    :
                        <div className="d-flex flex-row">
                            <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                <Form.Check
                                    type="checkbox"
                                    className="jt-table-check"
                                    onChange={(e) => toggleCategorieChecked()}
                                    checked={categorieChecked}
                                />
                            </Form>
                            <div className="d-flex align-items-center">{categorie['naam']}</div>
                            <Button
                                variant="outline-info"
                                size="sm"
                                onClick={toggleBody}
                                className="ml-3"
                            >
                                {showBody ? "Verberg" : "Toon"}
                            </Button>
                        </div>
                    }
                </th>
                <th className="jt-table-actions">
                    {categorie['naam'] != "Leeftijd"
                        ?
                        <>
                            <Button variant="info" className="rounded-circle" onClick={toggleCategorieEditMode}>{categorieInEditMode ? <FaTimes/> : <FaPen/>}</Button>
                        </>
                        :
                        <>
                        </>
                    }
                    {actionInProgress === "Delete" && actionOnId === -1
                        ?
                        <Button variant="danger" className="rounded-circle" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                        :
                        <Button variant="danger" className="rounded-circle" onClick={() => removeSubCategorieLocal(-1)}><FaTrashAlt/></Button>
                    }
                </th>
            </tr>
            </thead>
            <tbody className={showBody ? "" : "d-none"}>
                <tr>
                    <td colSpan={2} className="jt-table-vertical-center">
                        <div>
                            <Form onSubmit={addSubCategorieRemote}>
                                <Form.Group className="d-flex flex-row my-2">
                                    <Form.Label column lg={3}>Nieuwe subcategorie: </Form.Label>
                                    <Col xs={10} lg={7}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Subcategorie naam"
                                            value={subCategorieNaam}
                                            onChange={(e) => setSubCategorieNaam(e.target.value)}
                                            required
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        {actionInProgress === "Maak aan" && actionOnId === -1
                                            ?
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> Laden...
                                            </Button>
                                            :
                                            <Button variant="primary" type="submit" className="form-control">
                                                Maak aan
                                            </Button>
                                        }
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </td>
                </tr>
            {categorie['subCategorieen'].map(subCategorie => (
                <tr>
                    <td className="jt-table-vertical-center">
                        {subCategorie['id'] == subCategorieInEditMode
                        ?
                            <div>
                                <Form onSubmit={editSubCategorieRemote}>
                                    <Form.Group className="d-flex flex-row my-2">
                                        <Col xs={10}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Subcategorie naam"
                                                value={editedSubCategorieNaam}
                                                onChange={(e) => setEditedSubCategorieNaam(e.target.value)}
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            {actionInProgress === "Bewerk" && actionOnId === subCategorie['id']
                                                ?
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Laden...
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit" className="form-control">
                                                    Bewerk
                                                </Button>
                                            }
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        :
                            <div className="d-flex flex-row">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={(e) => toggleSubCategorieChecked(subCategorie['id'])}
                                        checked={checked.includes(subCategorie['id'])}
                                    />
                                </Form>
                                <div>{subCategorie['naam']}</div>
                            </div>
                        }
                    </td>
                    <td className="jt-table-actions">
                        <Button variant="info" className="rounded-circle" onClick={() => toggleSubCategorieEditMode(subCategorie['id'])}>{subCategorie['id'] == subCategorieInEditMode ? <FaTimes/> : <FaPen/>}</Button>
                        {actionInProgress === "Delete" && actionOnId === subCategorie['id']
                            ?
                            <Button variant="danger" className="rounded-circle" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </Button>
                            :
                            <Button variant="danger" className="rounded-circle" onClick={() => removeSubCategorieLocal(subCategorie['id'])}><FaTrashAlt/></Button>
                        }
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    )
}