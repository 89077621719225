import {useEffect, useState} from "react";
import {Redirect, useLocation, useHistory} from "react-router-dom";
import {Form, Button, Row, Col, Spinner} from 'react-bootstrap';

export default function AdminSpeelgoedFichePage({isLoggedIn, doelgroepen, userData, leeftijd, categorieen, speelgoed, onAddSpeelgoed, onUpdateSpeelgoed, onDeleteSpeelgoed}) {
    const location = useLocation();
    const [action, setAction] = useState('');

    const[id ,setId] = useState(-1);
    const [foto, setFoto] = useState({"url": "/images/placeholder.jpg", "bestand": undefined});
    const [titel, setTitel] = useState('');
    const [oudCode, setOudCode] = useState('');
    const [doelgroep, setDoelgroep] = useState(userData['sessDoelgroep']);
    const [selectLeeftijd, setSelectLeeftijd] = useState([]);
    const [selectSubCategorieen, setSelectSubCategorieen] = useState([]);
    const [merk, setMerk] = useState('');
    const [leverancier, setLeverancier] = useState('');
    const [aankoopDatum, setAankoopDatum] = useState('');
    const [inhoudItems, setInhoudItems] = useState([{"id": 1, "text": '', "aantal": 0, "kapot": 0, "tekort": 0}]);
    const [tipItems, setTipItems] = useState([{"id": 1, "text": ''}]);
    const [korteInhoud, setKorteInhoud] = useState('');
    const [bijlages, setBijlages] = useState([{"id": 1, "naam": "", "bestand": undefined}]);
    const [beschikbaar, setBeschikbaar] = useState(true);
    const [specialeAanvraag, setSpecialeAanvraag] = useState(false);

    const [actionInProgress, setActionInProgress] = useState('');

    const history = useHistory();

    useEffect(() => {
        let query = location.search;
        let variables = {};
        if (query) {
            query = query.slice(1,query.length); // Remove questionmark
            let variableStrings = query.split('&'); // Split variables
            variableStrings.forEach((variable)=>{
                variables[variable.split('=')[0]] = variable.split('=')[1]; // Split key and value and store in array
            })
            setAction(variables['action']); // Set action if in variables array
            if (variables['action'] == "bewerken") {
                if (speelgoed.filter(item => item['id'] == variables['id']).length > 0) {
                    let speelgoedItem = speelgoed.filter(item => item['id'] == variables['id'])[0];
                    setId(speelgoedItem['id']);
                    setFoto({"url": speelgoedItem['foto'] ? speelgoedItem['foto'] : "/images/placeholder.jpg", "bestand": undefined});
                    setTitel(speelgoedItem['naam']);
                    setOudCode(speelgoedItem['oudCode']);
                    setSelectLeeftijd(speelgoedItem['leeftijden']);
                    setSelectSubCategorieen(speelgoedItem['subCategorieen']);
                    setMerk(speelgoedItem['merk']);
                    setLeverancier(speelgoedItem['leverancier']);
                    setAankoopDatum(speelgoedItem['aankoopDatum']);
                    setInhoudItems([...speelgoedItem['inhoud'].map(item => ({...item, "original": 1, "removed": 0})), {"id": 0, "text": '', "aantal": 0, "kapot": 0, "tekort": 0}]);
                    setTipItems([...speelgoedItem['tips'].map(item => ({...item, "original": 1, "removed": 0})), {"id": 0, "text": ''}]);
                    setKorteInhoud(speelgoedItem['korteInhoud']);
                    setBijlages([...speelgoedItem['bijlages'].map(item => ({"id": item['id'], "naam": item['type'], "path": item['path'], "bestand": undefined, "original": 1, "removed": 0})), {"id": 0, "naam": "", "bestand": undefined}]);
                    setBeschikbaar(speelgoedItem['beschikbaar']);
                    setSpecialeAanvraag(speelgoedItem['specialeAanvraag']);
                }
            }
        }
    }, []);

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    async function addSpeelgoedRemote(e) {
        e.preventDefault();
        setActionInProgress("Upload");

        const formData = new FormData();
        formData.append('foto', foto['bestand']);
        formData.append('titel', jsonEscape(titel));
        formData.append('oudCode', jsonEscape(oudCode));
        formData.append('doelgroep', ""+doelgroep);
        selectSubCategorieen.forEach(item => {
            formData.append('subCategorieen[]', item);
        })
        selectLeeftijd.forEach(item => {
            formData.append('leeftijd[]', item);
        })
        formData.append('merk', jsonEscape(merk));
        formData.append('leverancier', jsonEscape(leverancier));
        formData.append('aankoopDatum', aankoopDatum);
        inhoudItems.forEach(item => {
            if (item['text'] && !item['removed']) {
                formData.append('inhoud[]', JSON.stringify({...item, "text": jsonEscape(item['text'])}));
            }
        })
        tipItems.forEach(item => {
            if (item['text'] && !item['removed']) {
                formData.append('tips[]', JSON.stringify({...item, "text": jsonEscape(item['text'])}));
            }
        })
        formData.append('korteInhoud', jsonEscape(korteInhoud));
        bijlages.forEach(item => {
            if (item['naam'] && item['bestand'] && !item['removed']) {
                formData.append('bijlagesBestand[]', item['bestand']);
                formData.append('bijlagesNaam[]', jsonEscape(item['naam']));
            }
        })
        formData.append('beschikbaar', beschikbaar ? "1" : "0");
        formData.append('specialeAanvraag', specialeAanvraag ? "1" : "0");

        const res = await fetch('../php/admin/speelgoedInsert.php', {
            method: 'POST',
            body: formData
        });
        const data = await res.json();

        if (data['ok']) {
            // Send update to state
            if (data['doelgroep'] == userData['sessDoelgroep'])
                onAddSpeelgoed(data['speelgoed']);

            // Redirect to speelgoedPage
            history.push("/admin/speelgoed?action=toegevoegd");
        } else {
            alert("Speelgoed aanmaken mislukt");
        }
        setActionInProgress("")
    }

    async function updateSpeelgoedRemote(e) {
        e.preventDefault();
        setActionInProgress("Upload");

        const formData = new FormData();
        formData.append('id', ""+id);
        formData.append('foto', foto['bestand']);
        formData.append('titel', jsonEscape(titel));
        formData.append('oudCode', jsonEscape(oudCode));
        formData.append('doelgroep', ""+doelgroep);
        selectSubCategorieen.forEach(item => {
            formData.append('subCategorieen[]', item);
        })
        selectLeeftijd.forEach(item => {
            formData.append('leeftijd[]', item);
        })
        formData.append('merk', jsonEscape(merk));
        formData.append('leverancier', jsonEscape(leverancier));
        formData.append('aankoopDatum', aankoopDatum);
        inhoudItems.forEach(item => {
            if (item['text'] || (item['removed'] && item['original'])) {
                formData.append('inhoud[]', JSON.stringify({...item, "text": jsonEscape(item['text'])}));
            }
        })
        tipItems.forEach(item => {
            if (item['text'] || (item['removed'] && item['original'])) {
                formData.append('tips[]', JSON.stringify({...item, "text": jsonEscape(item['text'])}));
            }
        })
        formData.append('korteInhoud', jsonEscape(korteInhoud));
        bijlages.forEach(item => {
            if ((item['naam'] && item['bestand'])  || item['original']) {
                formData.append('bijlagesBestand[]', item['bestand']);
                formData.append('bijlagesNaam[]', JSON.stringify({"id": item['id'], "naam": jsonEscape(item['naam']), "original": item['original'], "removed": item['removed']}));
            }
        })
        formData.append('beschikbaar', beschikbaar ? "1" : "0");
        formData.append('specialeAanvraag', specialeAanvraag ? "1" : "0");

        const res = await fetch('../php/admin/speelgoedUpdate.php', {
            method: 'POST',
            body: formData
        });
        const data = await res.json();

        if (data['ok']) {
            // Send update to state
            if (data['doelgroep'] == userData['sessDoelgroep'])
                onUpdateSpeelgoed(data['speelgoed']);
            else
                onDeleteSpeelgoed(data['speelgoed']['id']);

            // Redirect to speelgoedPage
            history.push("/admin/speelgoed?action=bewerkt");
        } else {
            alert("Speelgoed bewerken mislukt");
        }
        setActionInProgress("");
    }

    function changeItems(type, value, inhoudItem) {
        let newInhoudItems= [];
        inhoudItems.forEach((item) => {
            if (item['id'] == inhoudItem['id'] && type != "lostFocus") {
                let newInhoudItem = {};
                switch (type){
                    case "text":
                        newInhoudItem = {...inhoudItem, "text": value};
                        break;
                    case "aantal":
                        newInhoudItem = {...inhoudItem, "aantal": value};
                        break;
                    case "kapot":
                        newInhoudItem = {...inhoudItem, "kapot": value};
                        break;
                    case "tekort":
                        newInhoudItem = {...inhoudItem, "tekort": value};
                        break;
                    default:
                        newInhoudItem = inhoudItem;
                }
                newInhoudItems.push(newInhoudItem);
            } else {
                if (item['text'] !== ""){
                    newInhoudItems.push(item);
                } else {
                    newInhoudItems.push({...item, "removed": 1});
                }
            }
        })
        if (newInhoudItems[newInhoudItems.length-1]['text'] !== "") {
            newInhoudItems.push({"id": newInhoudItems[newInhoudItems.length-1]['id']-1, "text": "", "aantal": 0, "kapot": 0, "tekort": 0});
        }
        newInhoudItems[newInhoudItems.length-1]['removed'] = 0;
        setInhoudItems(newInhoudItems);
    }

    function changeTips(type, value, tipItem) {
        let newTipItems= [];
        tipItems.forEach((item) => {
            if (item['id'] == tipItem['id'] && type != "lostFocus") {
                let newTipItem = {};
                newTipItem = {...tipItem, "text": value};
                newTipItems.push(newTipItem);
            } else {
                if (item['text'] !== ""){
                    newTipItems.push(item);
                } else {
                    newTipItems.push({...item, "removed": 1});
                }
            }
        })
        if (newTipItems[newTipItems.length-1]['text'] !== "") {
            newTipItems.push({"id": newTipItems[newTipItems.length-1]['id']-1, "text": ""});
        }
        newTipItems[newTipItems.length-1]['removed'] = 0;
        setTipItems(newTipItems);
    }

    function changeFoto(e) {
        setFoto({"bestand": e.target.files[0], "url": URL.createObjectURL(e.target.files[0])});
    }

    function changeBijlage(type, e, bijlage) {
        let newBijlages= [];
        bijlages.forEach((item) => {
            if (item['id'] == bijlage['id'] && type != "lostFocus") {
                let newBijlage = {};
                switch (type){
                    case "bestand":
                        newBijlage = {...bijlage, "bestand": e.target.files[0]};
                        break;
                    case "naam":
                        newBijlage = {...bijlage, "naam": e.target.value};
                        break;
                    default:
                        newBijlage = bijlage;
                }
                newBijlages.push(newBijlage);
            } else {
                if (item['naam'] !== ""){
                    newBijlages.push(item);
                } else {
                    newBijlages.push({...item, "removed": 1});
                }
            }
        })
        if (newBijlages[newBijlages.length-1]['naam'] !== "") {
            newBijlages.push({"id": newBijlages[newBijlages.length-1]['id']-1, "naam": "", "bestand": ""});
        }
        newBijlages[newBijlages.length-1]['removed'] = 0;
        setBijlages(newBijlages);
    }

    function toggleSelect(type, id) {
        switch (type) {
            case "subCategorie":
                if (selectSubCategorieen.includes(id)) {
                    setSelectSubCategorieen(selectSubCategorieen.filter(item => item != id));
                    break;
                }
                setSelectSubCategorieen([...selectSubCategorieen, id]);
                break;
            case "leeftijd":
                if (selectLeeftijd.includes(id)) {
                    setSelectLeeftijd(selectLeeftijd.filter(item => item != id));
                    break;
                }
                setSelectLeeftijd([...selectLeeftijd, id]);
                break;
        }
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <main className="container">
            {action == "toevoegen" ? <h1>Admin speelgoed aanmaken</h1> : <h1>Admin speelgoed bewerken</h1>}
            <Form className="row" onSubmit={action == "toevoegen" ? addSpeelgoedRemote : updateSpeelgoedRemote}>
                <div className="col-12 col-md-4">
                    <Form.Group>
                        <img id="foto" src={foto['url']} alt="" className="m-auto"/>
                        <input id="foto-" type="file" className="form-control-file mt-2" onChange={(e) => changeFoto(e)}/>
                    </Form.Group>
                </div>
                <div className="col">

                    {/*Titel*/}
                    <Form.Group controlId="title">
                        <Form.Label>Titel:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Titel"
                            value={titel}
                            onChange={(e) => setTitel(e.target.value)}
                            required
                        />
                    </Form.Group>

                    {/*Oude code, leeftijd en categorieen*/}
                    <Form.Group as={Row} className="mb-0">
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Oude code:</Form.Label>
                                <Form.Control
                                    id="oudeCode"
                                    type="text"
                                    placeholder="Oude code"
                                    value={oudCode}
                                    onChange={(e) => setOudCode(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Leeftijd:</Form.Label>
                                <Form.Control
                                    id="leeftijd"
                                    as="select"
                                    htmlSize={3}
                                    custom
                                    value={selectLeeftijd}
                                    multiple
                                >
                                    {leeftijd['subCategorieen'].length > 0 ? leeftijd['subCategorieen'].map((sc) => (
                                            <option
                                                value={sc['id']}
                                                selected={selectLeeftijd.includes(sc['id'])}
                                                onClick={() => toggleSelect('leeftijd', sc['id'])}
                                            >{sc['naam']}</option>
                                        )
                                    ) : <option value="-1">Geen leeftijden</option>}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Categorieën:</Form.Label>
                                <Form.Control
                                    id="categorieen"
                                    as="select"
                                    htmlSize={7}
                                    custom
                                    value={selectSubCategorieen}
                                    multiple
                                >
                                    {categorieen.length > 0
                                        ?
                                        categorieen.map((c) => (
                                            c['subCategorieen'].length > 0
                                                ?
                                                <optgroup label={c['naam']}>
                                                    {c['subCategorieen'].map(sc => (
                                                        <option
                                                            value={sc['id']}
                                                            selected={selectSubCategorieen.includes(sc['id'])}
                                                            onClick={() => toggleSelect('subCategorie', sc['id'])}
                                                        >{sc['naam']}</option>
                                                    ))}
                                                </optgroup>
                                                :
                                                <></>
                                        ))
                                        :
                                        <option value="-1">Geen doelgroepen</option>
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Group>

                    {/*Merk, leverancier en aankoopdatum*/}
                    <Form.Group as={Row} className="mb-0">
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Merk:</Form.Label>
                                <Form.Control
                                    id="merk"
                                    type="text"
                                    placeholder="Merk"
                                    value={merk}
                                    onChange={(e) => setMerk(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Leverancier:</Form.Label>
                                <Form.Control
                                    id="leverancier"
                                    type="text"
                                    placeholder="Leverancier"
                                    value={leverancier}
                                    onChange={(e) => setLeverancier(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Aankoopdatum:</Form.Label>
                                <Form.Control
                                    id="aankoopDatum"
                                    type="date"
                                    value={aankoopDatum}
                                    onChange={(e) => setAankoopDatum(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Group>

                    {/*Inhoud*/}
                    <Form.Group as={Row} className="mb-0">
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>Inhoud:</Form.Label>
                                {inhoudItems.filter(inhoudItem => (!inhoudItem['removed'])).map(inhoudItem => (
                                    <Form.Control
                                        id={"inhoudItem-" + inhoudItem['id']}
                                        type="text"
                                        placeholder="Inhoud"
                                        value={inhoudItem['text']}
                                        onChange={(e) => changeItems("text", e.target.value, inhoudItem)}
                                        onBlur={(e) => changeItems("lostFocus", e.target.value, inhoudItem)}
                                        className="mb-1"
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <Form.Label>Aantal:</Form.Label>
                                {inhoudItems.filter(inhoudItem => (!inhoudItem['removed'])).map(inhoudItem => (
                                    <Form.Control
                                        id={"inhoudAantal-" + inhoudItem['id']}
                                        type="number"
                                        value={inhoudItem['aantal']}
                                        onChange={(e) => changeItems("aantal", e.target.value, inhoudItem)}
                                        className="mb-1"
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <Form.Label>Kapot:</Form.Label>
                                {inhoudItems.filter(inhoudItem => (!inhoudItem['removed'])).map(inhoudItem => (
                                    <Form.Control
                                        id={"inhoudKapot-" + inhoudItem['id']}
                                        type="number"
                                        value={inhoudItem['kapot']}
                                        onChange={(e) => changeItems("kapot", e.target.value, inhoudItem)}
                                        className="mb-1"
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <Form.Label>Tekort:</Form.Label>
                                {inhoudItems.filter(inhoudItem => (!inhoudItem['removed'])).map(inhoudItem => (
                                    <Form.Control
                                        id={"inhoudTekort-" + inhoudItem['id']}
                                        type="number"
                                        value={inhoudItem['tekort']}
                                        onChange={(e) => changeItems("tekort", e.target.value, inhoudItem)}
                                        className="mb-1"
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                    </Form.Group>

                    {/*Tips*/}
                    <Form.Group>
                        <Form.Label>Tips:</Form.Label>
                        {tipItems.filter(tipItem => (!tipItem['removed'])).map(tipItem => (
                            <Form.Control
                                id={"tipItem-" + tipItem['id']}
                                as="textarea"
                                placeholder="Geef een tip..."
                                value={tipItem['text']}
                                onChange={(e) => changeTips("text", e.target.value, tipItem)}
                                onBlur={(e) => changeTips("lostFocus", e.target.value, tipItem)}
                                rows={1}
                                className="mb-1"
                            />
                        ))}
                    </Form.Group>

                    {/*Korte inhoud*/}
                    <Form.Group controlId="korteInhoud">
                        <Form.Label>Korte beschrijving:</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Korte beschrijving"
                            value={korteInhoud}
                            onChange={(e) => setKorteInhoud(e.target.value)}
                        />
                    </Form.Group>

                    {/*Bijlages*/}
                    <Form.Group as={Row}>
                        <Col xs={12}>
                            <Form.Label>Bijlages:</Form.Label>
                        </Col>
                        {bijlages.filter(bijlage => (!bijlage['removed'])).map(bijlage => (
                            <>
                                <Col xs={6}>
                                    <Form.Control
                                        id={"bijlage-" + bijlage['id']}
                                        type="text"
                                        placeholder="Naam bijlage"
                                        value={bijlage['naam']}
                                        onChange={(e) => changeBijlage("naam", e, bijlage)}
                                        onBlur={(e) => changeBijlage("lostFocus", e.target.value, bijlage)}
                                        className="mb-1"
                                        required={bijlage['bestand']}
                                    />
                                </Col>
                                <Col xs={6} className="d-flex align-items-center">
                                    {bijlage['path']
                                    ?
                                        <a href={bijlage['path']} target="_blank">{bijlage['naam']}</a>
                                    :
                                        <input
                                            id={"file-" + bijlage['id']}
                                            type="file"
                                            onChange={(e) => changeBijlage("bestand", e, bijlage)}
                                            className="form-control-file mb-1"
                                        />
                                    }
                                </Col>
                            </>
                        ))}
                    </Form.Group>

                    {/*Beschikbaar*/}
                    <Form.Group>
                        <Form.Check
                            id="beschikbaar"
                            type="checkbox"
                            label="Beschikbaar"
                            onChange={(e) => setBeschikbaar(!beschikbaar)}
                            checked={beschikbaar}
                        />
                    </Form.Group>

                    {/*Speciale aanvraag*/}
                    <Form.Group>
                        <Form.Check
                            id="specialeAanvraag"
                            type="checkbox"
                            label="Speciale Aanvraag"
                            onChange={(e) => setSpecialeAanvraag(!specialeAanvraag)}
                            checked={specialeAanvraag}
                        />
                    </Form.Group>

                    {actionInProgress === "Upload"
                        ?
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        action === "toevoegen"
                            ?
                            <Button variant="primary" type="submit">
                                Speelgoed aanmaken
                            </Button>
                            :
                            <Button variant="primary" type="submit">
                                Speelgoed bewerken
                            </Button>
                    }
                </div>
            </Form>
        </main>
    )
}