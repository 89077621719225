import {BrowserRouter as Router, Route} from 'react-router-dom';
import { useState, useEffect } from 'react';
import {Alert} from "react-bootstrap";

// Import components
import Banner from "./components/Banner";
import Header from "./components/Header";
import Loading from "./components/Loading";
import Footer from "./components/Footer";

// Import pages
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import HomePage from "./pages/HomePage";
import AdminSpeelgoedPage from "./pages/admin/AdminSpeelgoedPage";
import AdminStatistiekPage from "./pages/admin/AdminStatistiekPage";
import AdminSpeelgoedFichePage from "./pages/admin/AdminSpeelgoedFichePage";
import AdminCategorieenPage from "./pages/admin/AdminCategorieenPage";
import SpeelgoedFichePage from "./pages/SpeelgoedFichePage";
import AdminDoelgroepenPage from "./pages/admin/AdminDoelgroepenPage";
import AdminPeriodesPage from "./pages/admin/AdminPeriodesPage";
import AdminGebruikersPage from "./pages/admin/AdminGebruikersPage";
import AdminReviewPage from "./pages/admin/AdminReviewPage";
import AdminReservatiePage from "./pages/admin/AdminReservatiePage";
import WinkelWagenPage from "./pages/WinkelWagenPage";
import WinkelWagenBevestigdPage from "./pages/WinkelWagenBevestigdPage";
import MijnReservatiesPage from "./pages/MijnReservatiesPage";

function App() {
    const [loading, setLoading] = useState(true);
    const [searchbarText, setSearchbarText] = useState('');
    const [sortParam, setSortParam] = useState({"param": "", "type": ""});
    const [sortParamRes, setSortParamRes] = useState({"param": "", "type": ""});

    const [userData, setUserData] = useState({"isLoggedIn": 0});
    const [doelgroepen, setDoelgroepen] = useState([]);
    const [filters, setFilters] = useState({"categorieen": [], "leeftijden": [], "showArchief": 0, "showUitgeleend": -1});
    const [speelgoed, setSpeelgoed] = useState([]);
    const [filteredSpeelgoed, setFilteredSpeelgoed] = useState([]);
    const [categorieen, setCategorieen] = useState([]);
    const [leeftijd, setLeeftijd] = useState({"id": -1, "naam": "Leeftijd", "subCategorieen": []});
    const [periodes, setPeriodes] = useState([]);
    const [gebruikers, setGebruikers] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [reservaties, setReservaties] = useState([]);
    const [filteredReservaties, setFilteredReservaties] = useState([]);
    const [winkelWagen, setWinkelWagen] = useState([]);
    const [winkelWagenResult, setWinkelWagenResult] = useState({"reservaties": [], "doubles": []});

    useEffect(() => {
        if (initDoelgroepen()) {
            setLoading(false);
        }
    }, []);

    // Login
    async function login(userData) {
        setLoading(true);
        setUserData(userData);
        const catRes = await initCategorie();
        const spgRes = await initSpeelgoed(catRes[0], catRes[1]);
        await initPeriodes();
        await initGebruikers();
        await initReviews();
        const resRes = await initReservaties();
        const fltSpgRes = await filterSpeelgoed(spgRes, searchbarText, filters);
        const fltResRes = await filterReservaties(resRes, fltSpgRes, searchbarText, filters);
        if (fltResRes) {
            setLoading(false);
        }
        setFilteredSpeelgoed(fltSpgRes);
        setFilteredReservaties(fltResRes);
    }

    // Logout
    async function logout() {
        setLoading(true);
        setSearchbarText('');
        setUserData({"isLoggedIn": 0});
        setDoelgroepen([]);
        setFilters({"categorieen": [], "leeftijden": [], "showArchief": 0});
        setSpeelgoed([]);
        setFilteredSpeelgoed([]);
        setCategorieen([]);
        setLeeftijd({"id": -1, "naam": "Leeftijd", "subCategorieen": []});
        setPeriodes([]);
        setGebruikers([]);
        setReviews([]);
        setReservaties([]);
        setFilteredReservaties([]);
        setWinkelWagen([]);
        const doelRes = await initDoelgroepen()
        if (doelRes) {
            setLoading(false);
        }
    }

    //Date formatter
    function formatDate(date) {
        let dateArray = date.split("-");
        let day = dateArray[2];
        let year = dateArray[0];
        let month;
        switch (parseInt(dateArray[1])) {
            case 1:
                month = "january"
                break;
            case 2:
                month = "february"
                break;
            case 3:
                month = "maart"
                break;
            case 4:
                month = "april"
                break;
            case 5:
                month = "mei"
                break;
            case 6:
                month = "juni"
                break;
            case 7:
                month = "juli"
                break;
            case 8:
                month = "augustus"
                break;
            case 9:
                month = "september"
                break;
            case 10:
                month = "oktober"
                break;
            case 11:
                month = "november"
                break;
            default:
                month = "december"
        }
        return day + " " + month + " " + year;
    }

    // Fetch doelgroepen
    async function initDoelgroepen() {
        setLoading(true);
        const res = await fetch('/php/doelgroepen.php');
        const data = await res.json();

        if (data['ok']) {
            setDoelgroepen(data['doelgroepen']);
            return true;
        }
        alert('Er was een probleem met het laden van de data');
        return false;
    }

    // Speelgoed functions
    function jsonEscapeReverse(str)  {
        return str.replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t");
    }

    async function initSpeelgoed(categorieen, leeftijd) {
        const res = await fetch('/php/speelgoed.php');
        const data = await res.json();

        if (data['ok']) {
            if (data['speelgoed']) {
                let newSpeelgoed = [];
                data['speelgoed'].forEach((item) => {
                    newSpeelgoed.push(
                        {...item,
                            "subCategorieen": item['subCategorieen'].filter(scId => ([].concat.apply([],categorieen.map(c => (c['subCategorieen'])).map(scen => (scen.map(sc => sc['id'])))).includes(scId))),
                            "leeftijden": item['subCategorieen'].filter(scId => (leeftijd['subCategorieen'].map(sc => (sc['id'])).includes(scId))),
                            "korteInhoud": jsonEscapeReverse(item['korteInhoud'])
                        });
                })
                setSpeelgoed(newSpeelgoed);
                return newSpeelgoed;
            }
            return speelgoed;
        } else {
            alert('Er was een probleem met het laden van het speelgoed');
        }
    }

    async function onAddSpeelgoed(newSpeelgoed) {
        const result = [...speelgoed, newSpeelgoed];
        setSpeelgoed(result);
        const fltSpgRes = await filterSpeelgoed(result, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function onUpdateSpeelgoed(updatedSpeelgoed) {
        let newSpeelgoed = [];
        speelgoed.forEach(item => {
            if(item['id'] === updatedSpeelgoed['id']) {
                newSpeelgoed.push(updatedSpeelgoed);
            } else {
                newSpeelgoed.push(item);
            }
        })
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function archiveSpeelgoed(ids, isArchief, archiefDatum) {
        let newSpeelgoed = [];
        speelgoed.forEach(item => {
            if (ids.includes(item['id'])) {
                newSpeelgoed.push({...item, "isArchief": isArchief, "archiefDatum": archiefDatum});
            } else {
                newSpeelgoed.push(item);
            }
        })
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function uitleenSpeelgoed(ids, uitgeleend) {
        let newSpeelgoed = [];
        speelgoed.forEach(item => {
            if (ids.includes(item['id'])) {
                newSpeelgoed.push({...item, "uitgeleend": uitgeleend});
            } else {
                newSpeelgoed.push(item);
            }
        })
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function onDeleteSpeelgoed(ids) {
        let newSpeelgoed = speelgoed.filter((item) => (!ids.includes(item['id'])));
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function onToggleBeschikbaar(id, status) {
        let newSpeelgoed = [];
        speelgoed.forEach(item => {
            if (id === item['id']) {
                newSpeelgoed.push({...item, "beschikbaar": status});
            } else {
                newSpeelgoed.push(item);
            }
        })
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    async function onToggleSpecialeAanvraag(id, status) {
        let newSpeelgoed = [];
        speelgoed.forEach(item => {
            if (id === item['id']) {
                newSpeelgoed.push({...item, "specialeAanvraag": status});
            } else {
                newSpeelgoed.push(item);
            }
        })
        setSpeelgoed(newSpeelgoed);
        const fltSpgRes = await filterSpeelgoed(newSpeelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, filters);
        setFilteredReservaties(fltResRes);
    }

    // Sort functions
    async function sortSpeelgoed(newSortParam, newSortType) {
        setSortParam({"param": newSortParam, "type": newSortType});
        console.log(newSortParam, " ", newSortType);

        switch (newSortParam) {
            case "naam":
                speelgoed.sort((a, b) => {
                    let naamA = a.naam.toUpperCase();
                    let naamB = b.naam.toUpperCase();
                    if (naamA < naamB) return newSortType === "asc" ? -1: 1;
                    if (naamA > naamB) return newSortType === "asc" ? 1: -1;
                    return 0;
                });
                break;
            default:
                speelgoed.sort((a, b) => {
                    let oudCodeA = a.oudCode.toUpperCase();
                    let oudCodeB = b.oudCode.toUpperCase();
                    if (oudCodeA < oudCodeB) return newSortType === "asc" ? -1: 1;
                    if (oudCodeA > oudCodeB) return newSortType === "asc" ? 1: -1;
                    return 0;
                });
                break;
        }

        const fltSpgResSearch = await filterSpeelgoed(speelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgResSearch);
        const fltResResSearch = await filterReservaties(reservaties, fltSpgResSearch, searchbarText, filters);
        setFilteredReservaties(fltResResSearch);
    }

    async function sortReservaties(newSortParam, newSortType) {
        setSortParamRes({"param": newSortParam, "type": newSortType});

        switch (newSortParam) {
            case "naam":
                reservaties.sort((a, b) => {
                    let naamA = speelgoed.find(spg => (a['speelgoedId'] === spg['id']))['naam'].toUpperCase();
                    let naamB = speelgoed.find(spg => (b['speelgoedId'] === spg['id']))['naam'].toUpperCase();
                    if (naamA < naamB) return newSortType === "asc" ? -1: 1;
                    if (naamA > naamB) return newSortType === "asc" ? 1: -1;
                    return 0;
                });
                break;
            case "gebruiker":
                reservaties.sort((a, b) => {
                    let naamA = gebruikers.find(gebr => (a['gebruikerId'] === gebr['id']))['naam'].toUpperCase();
                    let naamB = gebruikers.find(gebr => (b['gebruikerId'] === gebr['id']))['naam'].toUpperCase();
                    if (naamA < naamB) return newSortType === "asc" ? -1: 1;
                    if (naamA > naamB) return newSortType === "asc" ? 1: -1;
                    return 0;
                });
                break;
            default:
                reservaties.sort((a, b) => {
                    let oudCodeA = speelgoed.find(spg => (a['speelgoedId'] === spg['id']))['oudCode'].toUpperCase();
                    let oudCodeB = speelgoed.find(spg => (b['speelgoedId'] === spg['id']))['oudCode'].toUpperCase();
                    if (oudCodeA < oudCodeB) return newSortType === "asc" ? -1: 1;
                    if (oudCodeA > oudCodeB) return newSortType === "asc" ? 1: -1;
                    return 0;
                });
                break;
        }

        const fltSpgResSearch = await filterSpeelgoed(speelgoed, searchbarText, filters)
        setFilteredSpeelgoed(fltSpgResSearch);
        const fltResResSearch = await filterReservaties(reservaties, fltSpgResSearch, searchbarText, filters);
        setFilteredReservaties(fltResResSearch);
    }

    // Filter functions
    async function filterSpeelgoed(newSpeelgoed, searchbarText, filters) {
        let toFilter = newSpeelgoed;
        switch (filters['showArchief']) {
            case 0:
                toFilter = toFilter.filter(sg => (!sg['isArchief']));
                break;
            case 1:
                toFilter = toFilter.filter(sg => sg['isArchief']);
                break;
            default:
                break;
        }
        switch (filters['showUitgeleend']) {
            case 0:
                toFilter = toFilter.filter(sg => (!sg['uitgeleend']));
                break;
            case 1:
                toFilter = toFilter.filter(sg => sg['uitgeleend']);
                break;
            default:
                break;
        }
        toFilter = toFilter.filter(sg => ((sg['naam'].toLowerCase().includes(searchbarText.toLowerCase())) || (sg['oudCode'].toLowerCase().startsWith(searchbarText.toLowerCase()))));
        if (filters['leeftijden'].length !== 0) {
            toFilter = toFilter.filter(sg => (hasInCommon(sg['leeftijden'], filters['leeftijden'])));
        }
        filters['categorieen'].forEach((categorie) => {
            if (categorie['subCategorieen'].length !== 0) {
                toFilter = toFilter.filter(sg => (hasInCommon(sg['subCategorieen'], categorie['subCategorieen'])));
            }
        })
        return toFilter;
    }

    async function filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters) {
        let toFilter = newReservaties;
        switch (filters['showArchief']) {
            case 0:
                toFilter = toFilter.filter(res => (!res['isArchief']));
                break;
            case 1:
                toFilter = toFilter.filter(res=> res['isArchief']);
                filteredSpeelgoed = await filterSpeelgoed(speelgoed, searchbarText, {...filters, "showArchief": -1})
                break;
            default: break;
        }
        toFilter = toFilter.filter(res => (filteredSpeelgoed.map(spg => spg['id']).includes(res['speelgoedId'])));
        return toFilter;
    }

    function hasInCommon(array1, array2) {
        let result = false
        array2.forEach(item => {
            if (array1.includes(item)) {
                result = true;
            }
        })
        return result;
    }

    async function removeCategorieFromFilters(type, id) {
        let newFilters = {"categorieen": [], "leeftijden": [], "showArchief": 0};
        switch (type) {
            case "leeftijd":
                newFilters = {...filters, "leeftijden": []};
                break;
            case "categorie":
                newFilters = {...filters, "categorieen": filters['categorieen'].filter(c => (c['id'] !== id))};
                break;
            default: break;
        }
        setFilters(newFilters);
        const fltSpgRes = await filterSpeelgoed(speelgoed, searchbarText, newFilters)
        setFilteredSpeelgoed(fltSpgRes);
        const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, newFilters);
        setFilteredReservaties(fltResRes);
    }

    async function updateFilters(type, newValue, deleteValue, id) {
        let newFilters;
        switch (type) {
            case "search":
                setSearchbarText(newValue);
                const fltSpgResSearch = await filterSpeelgoed(speelgoed, newValue, filters)
                setFilteredSpeelgoed(fltSpgResSearch);
                const fltResResSearch = await filterReservaties(reservaties, fltSpgResSearch, newValue, filters);
                setFilteredReservaties(fltResResSearch);
                break;
            case "leeftijd":
                newFilters = {...filters, "leeftijden": [...filters['leeftijden'].filter(lt => (!deleteValue.includes(lt))), ...newValue]}
                setFilters(newFilters);
                const fltSpgResLeeftijd = await filterSpeelgoed(speelgoed, searchbarText, newFilters)
                setFilteredSpeelgoed(fltSpgResLeeftijd);
                const fltResResLeeftijd = await filterReservaties(reservaties, fltSpgResLeeftijd, searchbarText, newFilters);
                setFilteredReservaties(fltResResLeeftijd);
                break;
            case "subCategorie":
                let filterCategorie = filters['categorieen'].filter(c => (c['id'] === id));
                let filterSubCategorieen = []
                if (filterCategorie.length > 0) {
                    filterSubCategorieen = filterCategorie[0]['subCategorieen'];
                    filterSubCategorieen = [...filterSubCategorieen.filter(sc => (!deleteValue.includes(sc))), ...newValue];
                } else {
                    filterSubCategorieen = newValue;
                }
                filterCategorie = {"id": id, "subCategorieen": filterSubCategorieen};
                newFilters = {...filters, "categorieen": [...filters['categorieen'].filter(c => !(c['id'] === id)), filterCategorie]}
                setFilters(newFilters);
                const fltSpgResCat = await filterSpeelgoed(speelgoed, searchbarText, newFilters)
                setFilteredSpeelgoed(fltSpgResCat);
                const fltResResCat = await filterReservaties(reservaties, fltSpgResCat, searchbarText, newFilters);
                setFilteredReservaties(fltResResCat);
                break;
            case "showArchief":
                newFilters = {...filters, "showArchief": newValue};
                setFilters(newFilters);
                const fltSpgRes = await filterSpeelgoed(speelgoed, searchbarText, newFilters)
                setFilteredSpeelgoed(fltSpgRes);
                const fltResRes = await filterReservaties(reservaties, fltSpgRes, searchbarText, newFilters);
                setFilteredReservaties(fltResRes);
                break;
            case "showUitgeleend":
                newFilters = {...filters, "showUitgeleend": newValue};
                setFilters(newFilters);
                const fltSpgResUit = await filterSpeelgoed(speelgoed, searchbarText, newFilters)
                setFilteredSpeelgoed(fltSpgResUit);
                const fltResResUit = await filterReservaties(reservaties, fltSpgResUit, searchbarText, newFilters);
                setFilteredReservaties(fltResResUit);
                break;
            default: break;
        }
    }

    // Categorie functions
    async function initCategorie() {
        setLoading(true);
        const res = await fetch('/php/categorieen.php');
        const data = await res.json();

        if (data['ok']) {
            if (data['categorieen']) {
                let categorieen = data['categorieen'].filter((c) => (c['naam'] !== "Leeftijd"));
                let leeftijd = data['categorieen'].filter((c) => (c['naam'] === "Leeftijd"))[0]
                    ? data['categorieen'].filter((c) => (c['naam'] === "Leeftijd"))[0]
                    : {"id": -1, "naam": "Leeftijd", "subCategorieen": []};
                setCategorieen(categorieen);
                setLeeftijd(leeftijd);
                return [categorieen, leeftijd];
            }
            return [categorieen, leeftijd];
        }
        alert('Er was een probleem met het laden van de categorieen');
        return [];
    }

    function onAddCategorie(categorie) {
        if (categorie['naam'] === "Leeftijd") {
            setLeeftijd({...categorie, "subCategorieen": []});
        } else {
            setCategorieen([...categorieen, {...categorie, "subCategorieen": []}]);
        }
    }

    function onAddSubCategorie(subCategorie, categorie) {
        if (categorie['naam'] === "Leeftijd") {
            setLeeftijd({...categorie, "subCategorieen": [...categorie['subCategorieen'], subCategorie]});
            return;
        }
        let newCategorieen = []
        categorieen.forEach(c => {
            if (c['id'] === categorie['id']) {
                newCategorieen.push({...categorie, "subCategorieen": [...categorie['subCategorieen'], subCategorie]});
            } else {
                newCategorieen.push(c);
            }
        })
        setCategorieen(newCategorieen);
    }

    function onEditCategorie(newCategorieNaam, categorie) {
        let newCategorieen = [];
        categorieen.forEach(c => {
            if (c['id'] === categorie['id']) {
                newCategorieen.push({...categorie, "naam": newCategorieNaam});
            } else {
                newCategorieen.push(c);
            }
        })
        setCategorieen(newCategorieen);
    }

    function onEditSubCategorie(newSubCategorieNaam, categorie, subCategorieId) {
        if (categorie['naam'] === "Leeftijd") {
            let newSubCategorieen = []
            categorie['subCategorieen'].forEach(sc => {
                if (sc['id'] === subCategorieId) {
                    newSubCategorieen.push({"id": subCategorieId, "naam": newSubCategorieNaam})
                } else {
                    newSubCategorieen.push(sc);
                }
            })
            setLeeftijd({...categorie, "subCategorieen": newSubCategorieen});
            return;
        }
        let newCategorieen = [];
        categorieen.forEach(c => {
            if (c['id'] === categorie['id']) {
                let newSubCategorieen = []
                categorie['subCategorieen'].forEach(sc => {
                    if (sc['id'] === subCategorieId) {
                        newSubCategorieen.push({"id": subCategorieId, "naam": newSubCategorieNaam})
                    } else {
                        newSubCategorieen.push(sc);
                    }
                })
                newCategorieen.push({...categorie, "subCategorieen": newSubCategorieen});
            } else {
                newCategorieen.push(c);
            }
        })
        setCategorieen(newCategorieen);
    }

    function onRemoveSubCategorie(removedSubCategorieIds, categorie, categorieRemoved) {
        if (categorieRemoved) {
            setCategorieen(categorieen.filter((c) => (c['id'] !== categorie['id'])));
            return;
        }
        if (categorie['naam'] === "Leeftijd") {
            setLeeftijd({...categorie, "subCategorieen": categorie['subCategorieen'].filter(sc => (!removedSubCategorieIds.includes(sc['id'])))});
            return;
        }
        let newCategorieen = [];
        categorieen.forEach(c => {
            if (c['id'] === categorie['id']) {
                newCategorieen.push({
                    ...categorie,
                    "subCategorieen": categorie['subCategorieen'].filter(
                        sc => (!removedSubCategorieIds.includes(sc['id']))
                    )
                });
            } else {
                newCategorieen.push(c);
            }
        })
        setCategorieen(newCategorieen);
    }

    // Doelgroepen functies
    function onAddDoelgroep(doelgroep) {
        setDoelgroepen([...doelgroepen, doelgroep]);
    }

    function onEditDoelgroep(doelgroep) {
        let newDoelgroepen = []
        doelgroepen.forEach((item) => {
            if (item['id'] === doelgroep['id']) {
                newDoelgroepen.push({...doelgroep , "isArchief": item['isArchief']});
            } else {
                newDoelgroepen.push(item);
            }
        })
        setDoelgroepen(newDoelgroepen);
    }

    function onArchiveDoelgroep(id, isArchief) {
        let newDoelgroepen = []
        doelgroepen.forEach((doelgroep) => {
            if (doelgroep['id'] === id) {
                newDoelgroepen.push({...doelgroepen.find(item => (item['id'] === id)), "isArchief": isArchief});
            } else {
                newDoelgroepen.push(doelgroep);
            }
        })
        setDoelgroepen(newDoelgroepen);
    }

    // Periode Functions
    async function initPeriodes() {
        const res = await fetch('/php/periodes.php');
        const data = await res.json();

        if (data['ok']) {
            setPeriodes(data['periodes']);
            return data['periodes'];
        }
        return [];
    }

    function onAddPeriode(periode) {
        setPeriodes([...periodes, periode]);
    }

    function onEditPeriode(periode) {
        let newPeriodes = [];
        periodes.forEach(item => {
            if (item['id'] === periode['id']) {
                newPeriodes.push(periode);
            } else {
                newPeriodes.push(item);
            }
        })
        setPeriodes(newPeriodes);
    }

    function onDeletePeriodes(ids) {
        setPeriodes(periodes.filter(item => !(ids.includes(item['id']))));
    }

    // Gebruiker functions
    async function initGebruikers() {
        const res = await fetch('/php/gebruikers.php');
        const data = await res.json();

        if (data['ok']) {
            setGebruikers(data['gebruikers']);
            return data['gebruikers'];
        }
        return [];
    }

    function onAddGebruiker(gebruiker) {
        setGebruikers([...gebruikers, gebruiker]);
    }

    function onEditGebruiker(gebruiker) {
        let newGebruikers = [];
        gebruikers.forEach(item => {
            if (item['id'] === gebruiker['id']) {
                newGebruikers.push(gebruiker);
            } else {
                newGebruikers.push(item);
            }
        })
        setGebruikers(newGebruikers);
    }

    function onDeleteGebruiker(ids) {
        setGebruikers(gebruikers.filter(item => !(ids.includes(item['id']))));
    }

    // Review functions
    async function initReviews() {
        const res = await fetch('/php/reviews.php');
        const data = await res.json();

        if (data['ok']) {
            let newReviews = [];
            data['reviews'].forEach((item) => {
                newReviews.push(
                    {...item,
                        "inhoud": jsonEscapeReverse(item['inhoud'])
                    });
            })
            setReviews(newReviews);
            return data['reviews'];
        }
        return [];
    }

    function onAddReview(review) {
        setReviews([...reviews, review]);
    }

    function onDeleteReview(ids) {
        setReviews(reviews.filter(item => !(ids.includes(item['id']))));
    }


    // Reservatie functions
    async function initReservaties() {
        const res = await fetch('/php/reservaties.php');
        const data = await res.json();

        if (data['ok']) {
            setReservaties(data['reservaties']);
            return data['reservaties'];
        }
        return [];
    }

    async function onAddReservatie(reservatie) {
        let newReservaties = [...reservaties, reservatie];
        setReservaties(newReservaties);
        const resRes = await filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters);
        setFilteredReservaties(resRes);
    }

    async function onEditReservatie(id, speelgoedId, gebruikerId, periodeId) {
        let newReservaties = [];
        reservaties.forEach(item => {
            if (item['id'] === id) {
                newReservaties.push({...item, speelgoedId, gebruikerId, periodeId});
            } else {
                newReservaties.push(item);
            }
        })
        setReservaties(newReservaties);
        const resRes = await filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters);
        setFilteredReservaties(resRes);
    }

    async function onArchiveReservaties(ids, isArchief, archiefDatum) {
        let newReservaties = [];
        reservaties.forEach(item => {
            if (ids.includes(item['id'])) {
                newReservaties.push({...item, isArchief, archiefDatum});
            } else {
                newReservaties.push(item);
            }
        })
        setReservaties(newReservaties);
        const resRes = await filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters);
        setFilteredReservaties(resRes);
    }

    async function onConfirmReservaties(ids, bevestigd) {
        let newReservaties = [];
        reservaties.forEach(item => {
            if (ids.includes(item['id'])) {
                newReservaties.push({...item, bevestigd});
            } else {
                newReservaties.push(item);
            }
        })
        setReservaties(newReservaties);
        const resRes = await filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters);
        setFilteredReservaties(resRes);
    }

    async function onDeleteReservaties(ids) {
        let newReservaties = reservaties.filter(res => !ids.includes(res['id']));
        setReservaties(newReservaties);
        const resRes = await filterReservaties(newReservaties, filteredSpeelgoed, searchbarText, filters);
        setFilteredReservaties(resRes);
    }

    // Winkelwagen functions
    function onWinkelWagenAdd(speelgoedId, periodeId) {
        let newItem = {speelgoedId, periodeId, "gebruikerId": userData['id']};
        let id = 1;
        if (winkelWagen.length > 0) {
            id = winkelWagen[winkelWagen.length - 1]['id'] + 1;
        }
        newItem = {...newItem, id}
        setWinkelWagen([
            ...winkelWagen,
            newItem
            ]
        )
    }

    function onWinkelWagenEdit(id, periodeId) {
        let newWinkelwagen = [];
        winkelWagen.forEach(item => {
            if(item['id'] === id) {
                newWinkelwagen.push({...item, "periodeId": parseInt(periodeId)});
            } else {
                newWinkelwagen.push(item);
            }
        });
        setWinkelWagen(newWinkelwagen);
    }

    function onWinkelWagenDelete(ids) {
        setWinkelWagen(winkelWagen.filter(item => (!ids.includes(item['id']))));
    }

    async function onWinkelWagenCommit(data) {
        setWinkelWagen([]);
        setWinkelWagenResult(data);
        if (data['doubles'].length > 0) {
            setLoading(true);
            const resRes = await initReservaties();
            const fltResRes = await filterReservaties(resRes, speelgoed, searchbarText, filters);
            if (fltResRes) {
                setLoading(false);
            }
            setFilteredReservaties(fltResRes);
        } else {
            let newReservaties = [...reservaties, ...data['reservaties']];
            setReservaties(newReservaties);
            const fltResRes = await filterReservaties(newReservaties, speelgoed, searchbarText, filters);
            setFilteredReservaties(fltResRes);
        }
    }

    if (loading) {
        return (
            <main className="jt-loading-screen">
                <Loading/>
            </main>
        )
    }

    return (
        <Router>
            <div className="jt-wrapper">
                <Banner/>
                <Header
                    isLoggedIn={userData['isLoggedIn']}
                    role={userData['role']}
                    onLogout={logout}
                    updateFilters={updateFilters}
                    searchbarText={searchbarText}
                    winkelWagen={winkelWagen}
                    username={userData['user']}
                />
                {userData['isLoggedIn']
                    ?
                    <Alert show={userData['doelgroep'] !== userData['sessDoelgroep']} variant="danger">
                        <p className="mb-0">
                            U bent momenteel ingelogd bij Speelotheek&nbsp;
                            <strong>{doelgroepen.filter(doelgroep => (doelgroep['id'] === userData['sessDoelgroep']))[0]['naam']}</strong>.
                            Gelieve opnieuw in te loggen indien u wenst over te schakelen naar Speelotheek&nbsp;
                            <strong>{doelgroepen.filter(doelgroep => doelgroep['id'] === userData['doelgroep'])[0]['naam']}</strong>.
                        </p>
                    </Alert>
                    : <></>
                }
                <Route path="/login" exact render={() => (
                    <LoginPage
                        onLogin={login}
                        doelgroepen={doelgroepen}
                        isLoggedIn={userData['isLoggedIn']}
                    />
                )}
                />
                <Route path="/sign_up" exact render={() => (
                    <SignUpPage
                        doelgroepen={doelgroepen}
                        isLoggedIn={userData['isLoggedIn']}
                    />
                )}
                />
                <Route path="/" exact render={() => (
                    <HomePage
                        isLoggedIn={userData['isLoggedIn']}
                        filters={filters}
                        updateFilters={updateFilters}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        removeCategorieFromFilters={removeCategorieFromFilters}
                        filteredSpeelgoed={filteredSpeelgoed}
                        periodes={periodes}
                        onWinkelWagenAdd={onWinkelWagenAdd}
                        winkelWagen={winkelWagen}
                        reservaties={reservaties}
                        formatDate={formatDate}
                    />
                )}
                />
                <Route path="/admin/speelgoed" exact render={() => (
                    <AdminSpeelgoedPage
                        isLoggedIn={userData['isLoggedIn']}
                        filteredSpeelgoed={filteredSpeelgoed}
                        filters={filters}
                        updateFilters={updateFilters}
                        removeCategorieFromFilters={removeCategorieFromFilters}
                        archiveSpeelgoed={archiveSpeelgoed}
                        uitleenSpeelgoed={uitleenSpeelgoed}
                        onDeleteSpeelgoed={onDeleteSpeelgoed}
                        onToggleBeschikbaar={onToggleBeschikbaar}
                        onToggleSpecialeAanvraag={onToggleSpecialeAanvraag}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        sortSpeelgoed={sortSpeelgoed}
                        sortParam={sortParam}
                    />
                )}
                />
                <Route path="/admin/statistiek" exact render={() => (
                    <AdminStatistiekPage
                        isLoggedIn={userData['isLoggedIn']}
                        filteredSpeelgoed={filteredSpeelgoed}
                        filters={filters}
                        updateFilters={updateFilters}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        sortSpeelgoed={sortSpeelgoed}
                        sortParam={sortParam}
                        reservaties={reservaties}
                        periodes={periodes}
                    />
                )}
                />
                <Route path="/admin/speelgoedfiche" exact render={() => (
                    <AdminSpeelgoedFichePage
                        isLoggedIn={userData['isLoggedIn']}
                        doelgroepen={doelgroepen}
                        userData={userData}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        speelgoed={speelgoed}
                        onAddSpeelgoed={onAddSpeelgoed}
                        onUpdateSpeelgoed={onUpdateSpeelgoed}
                        onDeleteSpeelgoed={onDeleteSpeelgoed}
                    />
                )}
                />
                <Route path="/admin/categorieen" exact render={() => (
                    <AdminCategorieenPage
                        isLoggedIn={userData['isLoggedIn']}
                        categorieen = {categorieen}
                        leeftijd = {leeftijd}
                        onAddCategorie={onAddCategorie}
                        onAddSubCategorie={onAddSubCategorie}
                        onRemoveSubCategorie={onRemoveSubCategorie}
                        onEditCategorie={onEditCategorie}
                        onEditSubCategorie={onEditSubCategorie}
                    />
                )}
                />
                <Route path="/speelgoedfiche" exact render={() => (
                    <SpeelgoedFichePage
                        isLoggedIn={userData['isLoggedIn']}
                        speelgoed={speelgoed}
                        categorieen = {categorieen}
                        leeftijd = {leeftijd}
                        userData={userData}
                        reviews={reviews}
                        gebruikers={gebruikers}
                        periodes={periodes}
                        onAddReview={onAddReview}
                        reservaties={reservaties}
                        onWinkelWagenAdd={onWinkelWagenAdd}
                        winkelWagen={winkelWagen}
                        formatDate={formatDate}
                    />
                )}
                />
                <Route path="/admin/doelgroepen" exact render={() => (
                    <AdminDoelgroepenPage
                        isLoggedIn={userData['isLoggedIn']}
                        doelgroepen={doelgroepen}
                        onAddDoelgroep={onAddDoelgroep}
                        onEditDoelgroep={onEditDoelgroep}
                        onArchiveDoelgroep={onArchiveDoelgroep}
                    />
                )}
                />
                <Route path="/admin/periodes" exact render={() => (
                    <AdminPeriodesPage
                        isLoggedIn={userData['isLoggedIn']}
                        periodes={periodes}
                        onAddPeriode={onAddPeriode}
                        onEditPeriode={onEditPeriode}
                        formatDate={formatDate}
                        onDeletePeriodes={onDeletePeriodes}
                    />
                )}
                />
                <Route path="/admin/gebruikers" exact render={() => (
                    <AdminGebruikersPage
                        isLoggedIn={userData['isLoggedIn']}
                        gebruikers={gebruikers}
                        doelgroepen={doelgroepen}
                        onAddGebruiker={onAddGebruiker}
                        onEditGebruiker={onEditGebruiker}
                        onDeleteGebruiker={onDeleteGebruiker}
                        userData={userData}
                    />
                )}
                />
                <Route path="/admin/reviews" exact render={() => (
                    <AdminReviewPage
                        isLoggedIn={userData['isLoggedIn']}
                        filters={filters}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        reviews={reviews}
                        gebruikers={gebruikers}
                        updateFilters={updateFilters}
                        removeCategorieFromFilters={removeCategorieFromFilters}
                        filteredSpeelgoed={filteredSpeelgoed}
                        onDeleteReview={onDeleteReview}
                    />
                )}
                />
                <Route path="/admin/reservaties" exact render={() => (
                    <AdminReservatiePage
                        isLoggedIn={userData['isLoggedIn']}
                        filters={filters}
                        updateFilters={updateFilters}
                        removeCategorieFromFilters={removeCategorieFromFilters}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        filteredReservaties={filteredReservaties}
                        speelgoed={speelgoed}
                        gebruikers={gebruikers}
                        periodes={periodes}
                        onAddReservatie={onAddReservatie}
                        onEditReservatie={onEditReservatie}
                        onDeleteReservaties={onDeleteReservaties}
                        reservaties={reservaties}
                        onArchiveReservaties={onArchiveReservaties}
                        onConfirmReservaties={onConfirmReservaties}
                        formatDate={formatDate}
                        sortReservaties={sortReservaties}
                        sortParamRes={sortParamRes}
                        uitleenSpeelgoed={uitleenSpeelgoed}
                    />
                )}
                />
                <Route path="/winkelwagen" exact render={() => (
                    <WinkelWagenPage
                        isLoggedIn={userData['isLoggedIn']}
                        winkelWagen={winkelWagen}
                        onWinkelWagenDelete={onWinkelWagenDelete}
                        speelgoed={speelgoed}
                        periodes={periodes}
                        onWinkelWagenEdit={onWinkelWagenEdit}
                        reservaties={reservaties}
                        onWinkelWagenCommit={onWinkelWagenCommit}
                        formatDate={formatDate}
                    />
                )}
                />
                <Route path="/winkelwagenBevestigd" exact render={() => (
                    <WinkelWagenBevestigdPage
                        isLoggedIn={userData['isLoggedIn']}
                        winkelWagenResult={winkelWagenResult}
                        speelgoed={speelgoed}
                        periodes={periodes}
                        formatDate={formatDate}
                    />
                )}
                />
                <Route path="/mijnreservaties" exact render={() => (
                    <MijnReservatiesPage
                        isLoggedIn={userData['isLoggedIn']}
                        filters={filters}
                        updateFilters={updateFilters}
                        removeCategorieFromFilters={removeCategorieFromFilters}
                        categorieen={categorieen}
                        leeftijd={leeftijd}
                        userData={userData}
                        filteredReservaties={filteredReservaties}
                        speelgoed={speelgoed}
                        periodes={periodes}
                        onDeleteReservaties={onDeleteReservaties}
                        formatDate={formatDate}
                        reservaties={reservaties}
                        winkelWagen={winkelWagen}
                        filteredSpeelgoed={filteredSpeelgoed}
                        onWinkelWagenAdd={onWinkelWagenAdd}
                    />
                )}
                />
                <div className="jt-wrapper-fill"/>
                <Footer/>
                <Banner/>
            </div>
        </Router>
    );
}

export default App;
