import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaSearch} from 'react-icons/fa'
import {Link} from "react-router-dom";

export default function Searchbar({isLoggedIn, toggleSearchbar, searchbarActive, updateFilters, searchbarText}) {
    return (
        <>
        {isLoggedIn
            ?
                <>
                    <div className="jt-searchbar-expand">
                        <div className="flex-fill"></div>
                        <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                                <Tooltip>
                                    Zoeken
                                </Tooltip>
                            }
                        >
                            <div className="jt-searchbar-button border-0">
                                <a onClick={toggleSearchbar} className={searchbarActive ? "jt-searchbar-link jt-searchbar-link-active" : "jt-searchbar-link"}><FaSearch size="1.5em"/></a>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className="jt-searchbar jt-searchbar-full">
                        <div className="jt-searchbar-container">
                            <input
                                id="searchbar"
                                type="text"
                                placeholder="Zoeken"
                                className="jt-searchbar-input"
                                onChange={(e) => updateFilters("search", e.target.value, false)}
                                value={searchbarText}
                            />
                            {/*<OverlayTrigger*/}
                            {/*    key="bottom"*/}
                            {/*    placement="bottom"*/}
                            {/*    overlay={*/}
                            {/*        <Tooltip>*/}
                            {/*            Zoeken*/}
                            {/*        </Tooltip>*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <div className="jt-searchbar-button bg-white">*/}
                            {/*        <Link to="/" className="jt-searchbar-link"><FaSearch size="1.6em"/></Link>*/}
                            {/*    </div>*/}
                            {/*</OverlayTrigger>*/}
                        </div>
                    </div>
                </>
            :
                <div className="flex-fill"></div>
        }
        </>
    )
}