import {useState} from 'react';
import {Alert} from "react-bootstrap";

import SignUpForm from "../components/SignUpForm";
import {Redirect} from "react-router-dom";

export default function SignUpPage({doelgroepen, isLoggedIn}) {
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);

    function onSignUp(signUpSuccess) {
        setShowAlertSuccess(signUpSuccess);
        setShowAlertFail(!signUpSuccess);
    }

    if (isLoggedIn) {
        return (
            <Redirect to="/"/>
        )
    }

    return (
        <main className="container jt-login-container">
            <h1>Registreer</h1>
            <Alert show={showAlertSuccess} variant="success" onClose={() => setShowAlertSuccess(false)} dismissible>
                <Alert.Heading className="mb-0">Uw gegevens werden verzonden</Alert.Heading>
                <p className="mb-0">U kan inloggen vanaf dat een administrator uw account heeft geactiveerd.</p>
            </Alert>
            <Alert show={showAlertFail} variant="danger" onClose={() => setShowAlertFail(false)} dismissible>
                <Alert.Heading className="mb-0">Er is iets misgegaan</Alert.Heading>
                <p className="mb-0">Probeer een andere combinatie</p>
            </Alert>
            <SignUpForm doelgroepen={doelgroepen} onSignUp={onSignUp}/>
        </main>
    )
}
