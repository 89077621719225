import {Redirect} from "react-router-dom";
import {Badge, Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {FaArchive, FaPen, FaPlus, FaTimes} from "react-icons/fa";
import {useState} from "react";

export default function AdminDoelgroepenPage({isLoggedIn, doelgroepen, onAddDoelgroep, onEditDoelgroep, onArchiveDoelgroep}){
    const [showArchief, setShowArchief] = useState(0);
    const [addDoelgroep, setAddDoelgroep] = useState(false);
    const [doelgroepNaam, setDoelgroepNaam] = useState('');
    const [editDoelgroepNaam, setEditDoelgroepNaam] = useState('');
    const [editDoelgroep, setEditDoelgroep] = useState(-1);

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionId, setActionId] = useState(-1);

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    function toggleEditDoelgroep(id) {
        if (editDoelgroep === id) {
            setEditDoelgroep(-1);
        } else {
            setEditDoelgroep(id);
            setEditDoelgroepNaam(doelgroepen.find(item => item['id'] === id)['naam']);
        }
    }

    async function addDoelgroepRemote(e) {
        e.preventDefault();
        setActionInProgress("Maak aan");

        const res = await fetch('/php/admin/doelgroepAdd.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"doelgroepNaam": jsonEscape(doelgroepNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onAddDoelgroep(data['doelgroep']);
        } else {
            alert('Het aanmaken van een doelgroep is mislukt');
        }
        setActionInProgress("");
    }

    async function editDoelgroepRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");

        const res = await fetch('/php/admin/doelgroepEdit.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({editDoelgroep, "editDoelgroepNaam": jsonEscape(editDoelgroepNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onEditDoelgroep(data['doelgroep']);
            setEditDoelgroep(-1);
        } else {
            alert('Het bewerken van de doelgroep is mislukt');
        }
        setActionInProgress("");
    }

    async function toggleArchiveDoelgroepRemote(id) {
        setActionId(id);
        setActionInProgress("Archiveren");
        const res = await fetch('/php/admin/doelgroepArchive.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, "isArchief": !(doelgroepen.find(item => (item['id'] === id))['isArchief'])})
        });
        const data = await res.json();

        if (data['ok']) {
            onArchiveDoelgroep(data['id'], data['isArchief']);
        } else {
            alert('Het arciveren van de doelgroep is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <main className="container">
            <h1>Admin Doelgroepen</h1>
            <Row>
                <Form.Group className="mb-2">
                    <Col xs="auto" className="d-flex flex-row align-items-center">
                        <Form.Label className="mb-0">Archief:</Form.Label>
                    </Col>
                    <Col xs="auto">
                        <Form.Control
                            as="select"
                            value={showArchief}
                            onChange={e => setShowArchief(parseInt(e.target.value))}
                        >
                            <option value="-1">Toon alles</option>
                            <option value="0">Verberg archief</option>
                            <option value="1">Toon archief</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Row>
            <Table striped bordered hover size="sm" className="jt-table">
                <thead>
                <tr>
                    <th className="pl-4">Naam</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td/>
                    <td className="jt-table-actions">
                        <Button variant="success" className="rounded-circle" onClick={() => setAddDoelgroep(!addDoelgroep)}>{addDoelgroep ? <FaTimes/> : <FaPlus/>}</Button>
                    </td>
                </tr>
                {addDoelgroep
                    ?
                    <tr>
                        <td colSpan={2} className="jt-table-vertical-center">
                            <div>
                                <Form onSubmit={addDoelgroepRemote}>
                                    <Form.Group className="d-flex flex-row my-2">
                                        <Form.Label column lg={3}>Nieuwe doelgroep: </Form.Label>
                                        <Col xs={10} lg={7}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Doelgroep naam"
                                                value={doelgroepNaam}
                                                onChange={(e) => setDoelgroepNaam(e.target.value)}
                                                required
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            {actionInProgress === "Maak aan"
                                                ?
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    /> Laden...
                                                </Button>
                                                :
                                                <Button variant="primary" type="submit" className="form-control">
                                                    Maak aan
                                                </Button>
                                            }
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </td>
                    </tr>
                    :
                    <></>
                }
                {doelgroepen
                    .filter(item => (showArchief === -1) || (showArchief === 0 && !item['isArchief']) || (showArchief === 1 && item['isArchief']))
                    .map(doelgroep => (
                    <tr>
                        {doelgroep['id'] === editDoelgroep
                            ?
                            <td>
                                <div>
                                    <Form onSubmit={e => editDoelgroepRemote(e)}>
                                        <Form.Group className="d-flex flex-row my-2">
                                            <Col xs={10}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Doelgroep naam"
                                                    value={editDoelgroepNaam}
                                                    onChange={(e) => setEditDoelgroepNaam(e.target.value)}
                                                    required
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                {actionInProgress === "Bewerk"
                                                    ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /> Laden...
                                                    </Button>
                                                    :
                                                    <Button variant="primary" type="submit" className="form-control">
                                                        Bewerk
                                                    </Button>
                                                }
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </td>
                            :
                            <td className="pl-4">
                                <span className="mr-2">
                                    {doelgroep['naam']}
                                </span>
                                {doelgroep['isArchief']
                                    ?
                                    <Badge pill variant="archive" className="mr-2">
                                    <span className="mr-2">
                                        Gearchiveerd
                                    </span>
                                        <FaTimes as={Button}
                                                 onClick={() => toggleArchiveDoelgroepRemote(doelgroep['id'])}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                }
                            </td>
                        }
                        <td className="jt-table-actions">
                            <Button variant="info" className="rounded-circle" onClick={() => toggleEditDoelgroep(doelgroep['id'])}>{doelgroep['id'] === editDoelgroep ? <FaTimes/> : <FaPen/>}</Button>
                            {actionInProgress === "Archiveren" && actionId === doelgroep['id']
                                ?
                                <Button variant="archive" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="archive" className="rounded-circle" onClick={() => toggleArchiveDoelgroepRemote(doelgroep['id'])}><FaArchive/></Button>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </main>
    )
}