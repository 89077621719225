import {Spinner} from "react-bootstrap";

export default function Loading() {
    return (
        <h2>
            <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
            <span className="ml-2">
                Laden...
            </span>
        </h2>
    )
}