import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaSearch} from 'react-icons/fa'
import {Link} from "react-router-dom";

export default function SearchbarUnder({isLoggedIn, updateFilters, searchbarText}) {
    return (
        <>
            {isLoggedIn
                ?
                    <div className="jt-searchbar d-md-none">
                        <input
                            id="searchbar"
                            type="text"
                            placeholder="Zoeken"
                            className="jt-searchbar-input flex-fill"
                            onChange={(e) => updateFilters("search", e.target.value, false)}
                            value={searchbarText}
                        />
                        {/*<OverlayTrigger*/}
                        {/*    key="bottom"*/}
                        {/*    placement="bottom"*/}
                        {/*    overlay={*/}
                        {/*        <Tooltip>*/}
                        {/*            Zoeken*/}
                        {/*        </Tooltip>*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <div className="jt-searchbar-button bg-white">*/}
                        {/*        <Link to="/" className="jt-searchbar-link"><FaSearch size="1.6em"/></Link>*/}
                        {/*    </div>*/}
                        {/*</OverlayTrigger>*/}
                    </div>
                :
                    <div className="flex-fill"></div>
            }
        </>
    )
}