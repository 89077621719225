import {Card} from "react-bootstrap";
import WaarderingsSterren from "../components/WaarderingsSterren"

export default function Review({review, gebruiker}) {
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex flex-row align-items-center mb-2">
                    <Card.Title className="mb-0 mr-2">
                        {gebruiker['naam']}
                    </Card.Title>
                    <WaarderingsSterren
                        waardering={review['waardering']}
                    />
                </div>
                <Card.Subtitle>
                    {review['datum']}
                </Card.Subtitle>
                {/*TODO Bewerken als gebruiker is review['gebruikerId']*/}
            </Card.Header>
            <Card.Body>
                {review['inhoud']}
            </Card.Body>
        </Card>
    )
}