import {Redirect} from "react-router-dom";
import CategorieTable from "../../components/CategorieTable";
import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useState} from "react";

export default function AdminCategorieenPage({ isLoggedIn, categorieen, leeftijd, onAddCategorie, onAddSubCategorie, onRemoveSubCategorie, onEditCategorie, onEditSubCategorie }) {
    const [categorieNaam, setCategorieNaam] = useState();
    const [actionInProgress, setActionInProgress] = useState('');

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toDelete, setToDelete] = useState([]);
    const [removeCategorie, setRemoveCategorie] = useState(false);
    const [toRemoveCategorie, setToRemoveCategorie] = useState({"id": -1});

    const [removeChecked, setRemoveChecked] = useState(-1);

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    function onHideModalDelete() {
        setShowDeleteModal(false);
        setToDelete([]);
        setRemoveCategorie(false);
        setToRemoveCategorie({"id": -1});
        setActionInProgress("");
    }

    async function addCategorieRemote(e) {
        e.preventDefault();
        setActionInProgress("Categorie");

        const res = await fetch('/php/admin/addCategorie.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"categorieNaam": jsonEscape(categorieNaam)})
        });
        const data = await res.json();

        if (data['ok']) {
            onAddCategorie(data['categorie']);
        } else {
            alert('Het aanmaken van een nieuwe categorie is mislukt');
        }

        setCategorieNaam('');
        setActionInProgress("");
    }

    function onShowDeleteModal(ids, removeCategorie, categorie) {
        setToDelete(ids);
        setRemoveCategorie(removeCategorie);
        setToRemoveCategorie(categorie);
        setShowDeleteModal(true);
    }

    async function deleteSubCategorieRemote() {
        if (!removeCategorie && toDelete.length === 0) {
            onHideModalDelete();
            setRemoveChecked(toRemoveCategorie['id']);
            return;
        }
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/removeSubCategories.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"subCategorieIds": toDelete, removeCategorie, "categorieId": toRemoveCategorie['id']})
        });
        const data = await res.json();

        if (data['ok']) {
            onRemoveSubCategorie(data['removedSubCategorieIds'], toRemoveCategorie, data['categorieRemoved']);
        } else {
            alert('Het verwijderen van de subcategorie is mislukt');
        }
        onHideModalDelete();
        setRemoveChecked(toRemoveCategorie['id']);
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Admin Categorieën</h1>
                <Form onSubmit={addCategorieRemote}>
                    <Form.Group as={Row} controlId="formBasicUser">
                        <Form.Label column lg={3}>Nieuwe categorie: </Form.Label>
                        <Col xs={10} lg={7}>
                            <Form.Control
                                type="text"
                                placeholder="Categorie naam"
                                value={categorieNaam}
                                onChange={(e) => setCategorieNaam(e.target.value)}
                                required
                            />
                        </Col>
                        <Col xs={2}>
                            {actionInProgress === "Categorie"
                                ?
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Laden...
                                </Button>
                                :
                                <Button variant="primary" type="submit" className="form-control">
                                    Maak aan
                                </Button>
                            }
                        </Col>
                    </Form.Group>
                </Form>
                {leeftijd['id'] != -1
                    ?
                    <CategorieTable
                        categorie={leeftijd}
                        onAddSubCategorie={onAddSubCategorie}
                        onRemoveSubCategorie={onRemoveSubCategorie}
                        onEditCategorie={onEditCategorie}
                        onEditSubCategorie={onEditSubCategorie}
                        onShowDeleteModal={onShowDeleteModal}
                        removeChecked={removeChecked}
                        resetRemoveChecked={() => setRemoveChecked(-1)}
                    />
                    :
                    <Alert variant="danger">
                        <Alert.Heading>
                            Categorie leeftijd ontbreekt!!!
                        </Alert.Heading>
                        <p className="mb-0">
                            Gelieve een categorie aan te maken met de naam 'Leeftijd'.
                        </p>
                    </Alert>
                }
                {categorieen.map(categorie => (
                    <CategorieTable
                        categorie={categorie}
                        onAddSubCategorie={onAddSubCategorie}
                        onRemoveSubCategorie={onRemoveSubCategorie}
                        onEditCategorie={onEditCategorie}
                        onEditSubCategorie={onEditSubCategorie}
                        onShowDeleteModal={onShowDeleteModal}
                        removeChecked={removeChecked}
                        resetRemoveChecked={() => setRemoveChecked(-1)}
                    />
                ))}
            </main>

            <Modal show={showDeleteModal} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder reservatie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {removeCategorie ? "Weet u zeker dat u de volledige categorie wilt verwijderen?" : "Weet u zeker dat u " + toDelete.length + " subcategorie" + (toDelete.length > 1 ? "ën" : "") + " wilt verwijderen?"}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deleteSubCategorieRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}