import {Card, Form} from "react-bootstrap";
import {useState} from "react";

export default function Filter({ categorie, updateFilters, filters, initialShow }) {
    const [categorieChecked, setCategorieChecked] = useState(false);
    const [checked, setChecked] = useState(categorie['subCategorieen']
        .map(sc => (sc['id']))
        .filter(id => (
            filters['leeftijden']
                .concat.apply([],filters['categorieen'].map((c) => (c['subCategorieen'])))
                .includes(id)
            || filters['leeftijden'].includes(id)
            )
        )
    );

    function toggleCategorieChecked () {
        let newChecked = [];
        if (categorieChecked) {
            setChecked([]);
            updateFilters(categorie['naam'] == 'Leeftijd' ? 'leeftijd' : 'subCategorie', [], categorie['subCategorieen'].map((sc) => (sc['id'])), categorie['id']);
            setCategorieChecked(false);
        } else {
            newChecked = categorie['subCategorieen'].map((sc) => (sc['id']));
            updateFilters(categorie['naam'] == 'Leeftijd' ? 'leeftijd' : 'subCategorie', newChecked, newChecked, categorie['id']);
            setChecked(newChecked);
            setCategorieChecked(true);
        }
    }

    function toggleSubCategorieChecked(scId) {
        setCategorieChecked(false);
        if (checked.includes(scId)) {
            setChecked(checked.filter((id) => (id != scId)));
            updateFilters(categorie['naam'] == 'Leeftijd' ? 'leeftijd' : 'subCategorie', [], [scId], categorie['id']);
        } else {
            updateFilters(categorie['naam'] == 'Leeftijd' ? 'leeftijd' : 'subCategorie', [scId], [], categorie['id']);
            setChecked([...checked, scId]);
        }
    }

    return(
        <Card className="mr-2 mb-2">
            <Card.Body>
                <Card.Title>
                    <Form.Check
                        id={"all" + categorie['id']}
                        type="checkbox"
                        label={categorie['naam']}
                        onChange={(e) => toggleCategorieChecked()}
                        checked={categorieChecked}
                    />
                </Card.Title>
                <Card.Text>
                    {categorie['subCategorieen'].map((subCategorie) => (
                        <Form.Check
                            id={subCategorie['id']}
                            type="checkbox"
                            label={subCategorie['naam']}
                            onChange={(e) => toggleSubCategorieChecked(subCategorie['id'])}
                            checked={checked.includes(subCategorie['id'])}
                        />
                    ))}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}