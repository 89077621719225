import {Badge, Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReadMoreReact from 'read-more-react';

export default function SpeelgoedCard({ speelgoedItem, categorieen, leeftijd, addSpeelgoed ,reservaties, periodes, winkelWagen}) {
    return (
        <Card bg="light" >
            <Card.Header
                to={"/speelgoedFiche?id="+speelgoedItem['id']}
                className="px-3 pt-3"
            >
                <img
                    className="img-fluid"
                    src={speelgoedItem['foto'] ? speelgoedItem['foto'] : "/images/placeholder.jpg"}
                />
            </Card.Header>
            <Card.Body>
                <Card.Title
                    as={Link}
                    to={"/speelgoedfiche?id="+speelgoedItem['id']}
                    className="jt-card-title"
                >
                    {speelgoedItem['naam']}
                </Card.Title>
                <Card.Text>
                    <ReadMoreReact text={speelgoedItem['korteInhoud']}
                                   min={40}
                                   ideal={60}
                                   max={120}
                                   readMoreText={<a className="jt-read-more jt-clickable">Lees meer...</a>}
                    />
                </Card.Text>
                <div className="jt-card-tags">
                    {speelgoedItem['leeftijden'].map(lt => (
                        <Badge pill variant="secondary">
                            {leeftijd['subCategorieen']
                                .filter(item => (item['id'] == lt))
                                .map(item => (
                                    item['naam']
                                ))
                            }
                        </Badge>
                    ))}
                </div>
                <div className="jt-card-tags">
                    {speelgoedItem['subCategorieen'].map(scId => (
                        <Badge pill variant="secondary">
                            {categorieen.filter(c => (c['subCategorieen'].map(sc => sc['id']).includes(scId)))[0]['subCategorieen']
                                .filter(item => (item['id'] == scId))
                                .map(item => (
                                    item['naam']
                                ))
                            }
                        </Badge>
                    ))}
                </div>
                <Button
                    variant={
                        !speelgoedItem['beschikbaar'] ||
                        speelgoedItem['specialeAanvraag'] ||
                        speelgoedItem['isArchief'] ||
                        periodes
                            .filter(item => !(
                                item['isArchief'] ||
                                reservaties
                                    .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                    .map(res => res['periodeId'])
                                    .includes(item['id']) ||
                                winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                    .map(wwItem => wwItem['periodeId'])
                                    .includes(item['id'])
                            )).length === 0
                            ? "danger"
                            : "success"
                    }
                    disabled={
                        !speelgoedItem['beschikbaar'] ||
                        speelgoedItem['specialeAanvraag'] ||
                        speelgoedItem['isArchief'] ||
                        periodes
                            .filter(item => !(
                                item['isArchief'] ||
                                reservaties
                                    .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                    .map(res => res['periodeId'])
                                    .includes(item['id']) ||
                                winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                    .map(wwItem => wwItem['periodeId'])
                                    .includes(item['id'])
                            )).length === 0
                    }
                    className="mt-2"
                    onClick={() => addSpeelgoed(speelgoedItem['id'])}
                >
                    {speelgoedItem['specialeAanvraag'] ? "Speciale aanvraag" : "Reserveren"}
                </Button>
            </Card.Body>
        </Card>
    )
}