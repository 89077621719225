import {Dropdown, Nav, OverlayTrigger, Tooltip} from "react-bootstrap";

export default function CustomNavDropown ({title, faIcon, collapse, dropdownMenu}) {

    return (
        <Dropdown as={Nav.Item} className="d-flex align-items-center">
            <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                    <Tooltip className={"d-none d-"+collapse+"-block"}>
                        {title}
                    </Tooltip>
                }
            >
                <Dropdown.Toggle as={Nav.Link}>
                    {faIcon}
                    <span className="d-md-none ml-2">{title}</span>
                </Dropdown.Toggle>
            </OverlayTrigger>
            {dropdownMenu}
        </Dropdown>
    )
}