import {useState} from 'react';
import {Form, Button, Row, Col, Spinner} from "react-bootstrap";
import {sha256} from 'js-sha256';

export default function SignUpForm({doelgroepen, onSignUp}) {
    const [user,setUser] = useState('');
    const [email,setEmail] = useState('');
    const [pass,setPass] = useState('');
    const [passConfirm,setPassConfirm] = useState('');
    const [doelgroep,setDoelgroep] = useState(-1);

    const [actionInProgress, setActionInProgress] = useState('');

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        //Validate password
        if (doelgroep === -1) return alert('Selecteer een doelgroep');
        if (pass !== passConfirm || email === '' || pass === '') return alert('Zorg dat wachtwoord juist bevestigd is');

        //Set spinner
        setActionInProgress("Registreer");

        //Encrypt pass
        const encrpass = sha256(pass);

        const res = await fetch('./php/signUp.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"user": jsonEscape(user), encrpass, "email": jsonEscape(email), doelgroep})
        });
        const data = await res.json();

        //Show user feedback
        data['ok'] ? onSignUp(true) : onSignUp(false);

        setUser('');
        setPass('');
        setEmail('')
        setPassConfirm('');
        setActionInProgress('');
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row} controlId="formUser">
                <Form.Label column lg={2}>Naam:</Form.Label>
                <Col lg={10}>
                    <Form.Control
                        type="text"
                        placeholder="Voer gebruikersnaam in"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        required
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEmail">
                <Form.Label column lg={2}>Emailadres:</Form.Label>
                <Col lg={10}>
                    <Form.Control
                        type="email"
                        placeholder="Voer emailadres in"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDoelgroep">
                <Form.Label column lg={2}>Doelgroep:</Form.Label>
                <Col lg={10}>
                    <Form.Control
                        as="select"
                        value={doelgroep}
                        onChange={(e) => setDoelgroep(e.target.value)}
                    >
                        <option value="-1">Kies...</option>
                        {doelgroepen.length > 0 ? doelgroepen.filter(item => !item['isArchief']).map((doelgroep) => (
                                <option value={doelgroep['id']}>{doelgroep['naam']}</option>
                            )
                        ) : <option value="-1">Geen doelgroepen</option>}
                    </Form.Control>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                 <Col lg={6} className="mb-3">
                     <Form.Label>Wachtwoord:</Form.Label>
                     <Form.Control
                         id="formPassword"
                         type="password"
                         placeholder="Wachtwoord"
                         value={pass}
                         onChange={(e) => setPass(e.target.value)}
                         required
                     />
                </Col>
                <Col lg={6} className="mb-3">
                    <Form.Label>Bevestig wachtwoord:</Form.Label>
                    <Form.Control
                        id="formPasswordConfirm"
                        type="password"
                        placeholder="Wachtwoord"
                        value={passConfirm}
                        onChange={(e) => setPassConfirm(e.target.value)}
                        required
                    />
                </Col>
            </Form.Group>

            {actionInProgress === "Registreer"
                ?
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> Laden...
                </Button>
                :
                <Button variant="primary" type="submit">
                    Registreer
                </Button>
            }
        </Form>
    )
}
