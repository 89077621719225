import {Link, Redirect} from "react-router-dom";
import Filters from "../components/Filters";
import {Button, CardColumns, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import SpeelgoedCard from "../components/SpeelgoedCard";
import {useState} from "react";
import SpeelgoedToegevoegd from "../components/SpeelgoedToegevoegd";

export default function HomePage({ isLoggedIn, filters, updateFilters, categorieen, leeftijd, userData,
                                     removeCategorieFromFilters, filteredSpeelgoed, periodes, onWinkelWagenAdd,
                                     winkelWagen, reservaties, formatDate }) {
    const [addSpeelgoedToWinkelWagen, setAddSpeelgoedToWinkelWagen] = useState(false);
    const [toAddSpeelgoed, setToAddSpeelgoed] = useState({"speelgoedId": -1, "periodeId": -1});
    const [showToegevoegd, setShowToegevoegd] = useState(false);
    const [toegevoedSpeelgoed, setToegevoegdSpeelgoed] = useState({});
    const [toegevoegdPeriode, setToegevoegdPeriode] = useState({"startDatum": "1995-07-13", "eindDatum": "1995-07-13"});

    const [actionInProgress, setActionInProgress] = useState('');

    function onShowModal(id) {
        setToAddSpeelgoed({...toAddSpeelgoed, "speelgoedId": id});
        setAddSpeelgoedToWinkelWagen(true);
    }

    function onHideModal() {
        setToAddSpeelgoed({"speelgoedId": -1, "periodeId": -1})
        setAddSpeelgoedToWinkelWagen(false);
    }

    function onShowModalToegevoegd(speelgoedId, periodeId) {
        setToegevoegdSpeelgoed(filteredSpeelgoed.find(item => (item['id'] === speelgoedId)));
        setToegevoegdPeriode(periodes.find(item => (item['id'] === periodeId)));
        setShowToegevoegd(true);
    }

    function onHideModalToegevoegd() {
        setShowToegevoegd(false);
    }

    function onWinkelWagenAddLocal(e) {
        e.preventDefault();
        if (!toAddSpeelgoed['periodeId'] || toAddSpeelgoed['periodeId'] === -1) return alert("Selecteer een periode");
        if (winkelWagen.map(item => item['speelgoedId']).includes(toAddSpeelgoed['speelgoedId'] && winkelWagen.filter(item => (item['speelgoedId'] === toAddSpeelgoed['speelgoedId'])).map(item => item['periodeId']).includes(toAddSpeelgoed['periodeId'])))
            return alert("Deze reservatie zit reeds in uw winkelwagen");

        setActionInProgress("Toevoegen");
        onWinkelWagenAdd(toAddSpeelgoed['speelgoedId'], toAddSpeelgoed['periodeId']);
        onShowModalToegevoegd(toAddSpeelgoed['speelgoedId'], toAddSpeelgoed['periodeId']);
        onHideModal();
        setActionInProgress("");
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Hoofdpagina</h1>
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />
                <CardColumns className="jt-card-columns">
                    {filteredSpeelgoed.map(item => (
                        <SpeelgoedCard
                            speelgoedItem={item}
                            categorieen={categorieen}
                            leeftijd={leeftijd}
                            addSpeelgoed={onShowModal}
                            reservaties={reservaties}
                            periodes={periodes}
                            winkelWagen={winkelWagen}
                        />
                    ))}
                </CardColumns>
            </main>

            <Modal show={addSpeelgoedToWinkelWagen} onHide={() => onHideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Toevoegen aan winkelwagen</Modal.Title>
                </Modal.Header>
                <Form onSubmit={onWinkelWagenAddLocal}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Periode:</Form.Label>
                            <Form.Control
                                as="select"
                                value={toAddSpeelgoed['periodeId']}
                                onChange={e => setToAddSpeelgoed({...toAddSpeelgoed, "periodeId": parseInt(e.target.value)})}
                                required
                            >
                                <option value="-1">Kies...</option>
                                {periodes.filter(item => !item['isArchief']).length > 0 ? periodes
                                    .filter(item => !(
                                        item['isArchief'] || reservaties
                                            .filter(res => (!res['isArchief'] && res['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                            .map(res => res['periodeId'])
                                            .includes(item['id']) ||
                                        winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                            .map(wwItem => wwItem['periodeId'])
                                            .includes(item['id'])
                                    ))
                                    .map((periode) => (
                                        <option
                                            value={periode['id']}
                                        >
                                            {"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}
                                        </option>
                                    )
                                ) : <option value="-1">Geen periodes beschikbaar</option>}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModal()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Toevoegen"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Toevoegen
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <SpeelgoedToegevoegd
                show={showToegevoegd}
                onHide={() => onHideModalToegevoegd()}
                item={toegevoedSpeelgoed}
                periode={toegevoegdPeriode}
                formatDate={formatDate}
            />
        </>
    )
}