import ReactStars from "react-rating-stars-component/dist/react-stars";

export default function waarderingsSterren({waardering}) {
    return (
        <ReactStars
            count={5}
            size={24}
            value={waardering}
            edit={false}
            activeColor="#ffd700"
        />
    )
}