import {Nav, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function CustomNavLink ({title, faIcon, collapse, to, shoppingCartLength}) {

    return (
        <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
                <Tooltip className={"d-none d-"+collapse+"-block"}>
                    {title}
                </Tooltip>
            }
        >
            <Nav.Link to={to} as={Link} className="position-relative">
                {faIcon}
                <span className="d-md-none ml-2">{title}</span>
                {shoppingCartLength > 0
                    ?
                    <div className="jt-shopping-cart-number">
                        <span>{shoppingCartLength}</span>
                    </div>
                    :
                    <></>
                }
            </Nav.Link>
        </OverlayTrigger>
    )
}

CustomNavLink.defaultProps = {
    shoppingCartLength: 0
}