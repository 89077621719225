import {Link, Redirect} from "react-router-dom";
import {Badge, Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import Filters from "../../components/Filters";
import {FaArchive, FaCheck, FaPen, FaPlus, FaTimes, FaTrashAlt, FaCaretUp, FaCaretDown, FaArrowRight} from "react-icons/fa";
import {useState} from "react";
import SearchSelect from "../../components/SearchSelect";

export default function AdminReservatiePage({isLoggedIn, filters, updateFilters, categorieen, leeftijd, userData, removeCategorieFromFilters,
                                            filteredReservaties, speelgoed, gebruikers, periodes, onAddReservatie, onEditReservatie,
                                            onDeleteReservaties, reservaties, onArchiveReservaties, onConfirmReservaties, formatDate, sortReservaties, sortParamRes, uitleenSpeelgoed}) {
    const [allChecked, setAllChecked] = useState(false);
    const [reservatiesChecked, setReservatiesChecked] = useState([]);
    const [filterPeriode, setFilterPeriode] = useState(-1);
    const [filterGebruiker, setFilterGebruiker] = useState(-1);
    const [filterBevestigd, setFilterBevestigd] = useState(-1);
    const [returnList, setReturnList] = useState(false);
    const [returnPeriode, setReturnPeriode] = useState(-1);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [toAddReservatie, setToAddReservatie] = useState({"periodeId": -1});
    const [toEditReservatie, setToEditReservatie] = useState({"speelgoedId": -1, "gebruikerId": -1, "periodeId": -1});
    const [toDelete, setToDelete] = useState([]);

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionId, setActionId] = useState(-1);
    const [terugDatum, setTerugDatum] = useState('');

    function onHideModalAdd() {
        setShowAddModal(false);
        setToAddReservatie({"periodeId": -1});
    }

    function onHideModalEdit() {
        setShowEditModal(false);
        setToEditReservatie({"speelgoedId": -1, "gebruikerId": -1, "periodeId": -1});
    }

    function onHideModalDelete() {
        setShowDeleteModal(false);
        setToDelete([]);
        setActionId(-1);
    }

    function toggleAllChecked() {
        if (allChecked) {
            setReservatiesChecked([]);
            setAllChecked(false);
        } else {
            setReservatiesChecked(filteredReservaties
                .filter(item => (
                    (filterPeriode === -1 || item['periodeId'] === filterPeriode)
                    && (filterGebruiker === -1 || item['gebruikerId'] === filterGebruiker)
                    && (filterBevestigd === -1 || item['bevestigd'] === filterBevestigd)
                    && (!returnList || !filteredReservaties
                            .filter(res => (res['speelgoedId'] === item['speelgoedId'] && res['gebruikerId'] === item['gebruikerId']))
                            .map(res => res['periodeId'])
                            .includes(returnPeriode)
                    )
                ))
                .map((reservatie) => (reservatie['id']))
            );
            setAllChecked(true);
        }
    }

    function toggleReservatieChecked(speelgoedId) {
        setAllChecked(false);
        if (reservatiesChecked.includes(speelgoedId)) {
            setReservatiesChecked(reservatiesChecked.filter((id) => (id !== speelgoedId)))
        } else {
            setReservatiesChecked([...reservatiesChecked, speelgoedId]);
        }
    }

    function addReservatieLocal() {
        setShowAddModal(true);
    }

    function editReservatieLocal(id) {
        let reservatie = reservaties.find(item => (item['id'] === id));
        setToEditReservatie({
            id,
            "speelgoedId": reservatie['speelgoedId'],
            "gebruikerId": reservatie['gebruikerId'],
            "periodeId": reservatie['periodeId']
        })
        setShowEditModal(true);
    }

    function deleteReservatieLocal(id) {
        setShowDeleteModal(true);
        setActionId(id);
        let ids = [];
        if (id === -1) {
            ids = reservatiesChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    async function addReservatieRemote(e) {
        e.preventDefault();
        setActionInProgress("Maak aan");

        if (toAddReservatie["periodeId"] === -1) return alert("Selecteer een periode");
        const res = await fetch('/php/admin/reservatieAdd.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(toAddReservatie)
        });
        const data = await res.json();

        if (data['ok']) {
            onAddReservatie(data['reservatie']);
        } else {
            alert('Het aanmaken van een reservatie is mislukt');
        }
        onHideModalAdd();
        setActionInProgress("");
    }

    async function editReservatieRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");

        const res = await fetch('/php/admin/reservatieEdit.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(toEditReservatie)
        });
        const data = await res.json();

        if (data['ok']) {
            onEditReservatie(data['id'], data['speelgoedId'], data['gebruikerId'], data['periodeId']);
        } else {
            alert('Het bewerken van een reservatie is mislukt');
        }

        onHideModalEdit();
        setActionInProgress("");
    }

    async function archiveReservatieRemote(id, isArchief) {
        setActionId(id);
        setActionInProgress("Archiveren");
        let ids = [];
        if (id === -1) {
            ids = reservatiesChecked;
        } else {
            ids.push(id);
        }
        let today = new Date();
        let todayString = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        const res = await fetch('/php/admin/reservatieArchive.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                ids,
                "isArchief" : isArchief ? 1 : 0,
                "archiefDatum": todayString
            })
        });
        const data = await res.json();

        if (data['ok']) {
            onArchiveReservaties(data['ids'], data['isArchief'], data['archiefDatum']);
        } else {
            alert('Het archiveren van een reservatie is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    async function uitleenSpeelgoedRemote(id, uitgeleend) {
        setActionId(id);
        setActionInProgress("Uitlenen");
        let ids = [];
        if (id == -1) {
            ids = reservaties.filter(r => reservatiesChecked.includes(r['id'])).map(r => r['speelgoedId']);
        } else {
            ids.push(reservaties.filter(r => r['id'] === id).map(r => r['speelgoedId'])[0]);
        }
        const res = await fetch('/php/admin/speelgoedUitlenen.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ids, "uitgeleend": uitgeleend ? 1 : 0})
        });
        const data = await res.json();

        if (data['ok']) {
            uitleenSpeelgoed(data['ids'], data['uitgeleend']);
        } else {
            alert('Het uitlenen is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    async function confirmReservatieRemote(id, bevestigd) {
        setActionId(id);
        setActionInProgress("Bevestigen");
        let ids = [];
        if (id === -1) {
            ids = reservatiesChecked;
        } else {
            ids.push(id);
        }
        const res = await fetch('/php/admin/reservatieConfirm.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                ids,
                "bevestigd": bevestigd ? 1 : 0
            })
        });
        const data = await res.json();

        if (data['ok']) {
            onConfirmReservaties(data['ids'], data['bevestigd']);
        } else {
            alert('Het bevestigen van een reservatie is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    async function deleteReservatieRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/reservatieDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeleteReservaties(data['ids']);
        } else {
            alert('Het verwijderen van een reservatie is mislukt');
        }
        onHideModalDelete();
        setActionInProgress("");
        setActionId(-1);
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container-fluid">
                <h1 className="jt-no-print">Admin Reservaties</h1>
                {returnList
                    ?
                    <h1 className="jt-print">
                        <span className="mr-2">Lijst binnen brengen</span>
                        {terugDatum !== '' ? <span className="jt-subtitle">Deadline: {formatDate(terugDatum)}</span> : <></>}
                    </h1>
                    :
                    <></>
                }
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />

                <Row className="jt-no-print">
                    {/*Filter periodes*/}
                    <Form.Group className="px-3 py-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Periode:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={filterPeriode}
                                onChange={e => setFilterPeriode(parseInt(e.target.value))}
                                required
                            >
                                <option value="-1">Alle periodes</option>
                                {periodes.filter(item => (!item['isArchief'] || filters['showArchief'])).length > 0 ? periodes.filter(item => (!item['isArchief'] || filters['showArchief'])).map((periode) => (
                                        <option
                                            value={periode['id']}
                                        >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                    )
                                ) : <option value="-1">Geen periodes beschikbaar</option>}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    {/*Filter Gebruikers*/}
                    <Form.Group className="px-3 py-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Gebruiker:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={filterGebruiker}
                                onChange={e => setFilterGebruiker(parseInt(e.target.value))}
                                required
                            >
                                <option value="-1">Alle gebruikers</option>
                                {gebruikers
                                    .filter(item => ((item['doelgroepId'] === userData['sessDoelgroep']) && (!item['isArchief'])))
                                    .length > 0 ?
                                    gebruikers
                                        .filter(item => ((item['doelgroepId'] === userData['sessDoelgroep']) && (!item['isArchief'])))
                                    .map((gebruiker) => (
                                        <option
                                            value={gebruiker['id']}
                                        >{gebruiker['naam']}</option>
                                    )
                                ) : <option value="-1">Geen gebruikers beschikbaar</option>}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    {/*Filter Bevestigd*/}
                    <Form.Group className="px-3 py-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Bevestigd:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={filterBevestigd}
                                onChange={e => setFilterBevestigd(parseInt(e.target.value))}
                                required
                            >
                                <option value="-1">Toon alles</option>
                                <option value="0">Niet bevestigd</option>
                                <option value="1">Bevestigd</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    {/*Lijst binnenbrengen*/}
                    <Form.Group className="px-3 py-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Check
                                type="switch"
                                id="switch"
                                value={returnList}
                                onChange={() => setReturnList(!returnList)}
                                label="Binnenbrengen lijst"
                            />
                        </Col>
                        {returnList
                            ?
                            <>
                                <Col xs="auto">
                                    <Form.Control
                                        as="select"
                                        value={returnPeriode}
                                        onChange={e => setReturnPeriode(parseInt(e.target.value))}
                                        required
                                    >
                                        <option value="-1">Alle periodes</option>
                                        {periodes.filter(item => (!item['isArchief'] || filters['showArchief'])).length > 0 ? periodes.filter(item => (!item['isArchief'] || filters['showArchief'])).map((periode) => (
                                                <option
                                                    value={periode['id']}
                                                >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                            )
                                        ) : <option value="-1">Geen periodes beschikbaar</option>}
                                    </Form.Control>
                                </Col>
                                <Col xs="auto">
                                    <Form.Label htmlFor="binnenbrengenDatum">Deadline: </Form.Label>
                                    <Form.Control
                                        id="binnenbrengenDatum"
                                        type="date"
                                        value={terugDatum}
                                        onChange={(e) => setTerugDatum(e.target.value)}
                                    />
                                </Col>
                            </>
                            :
                            <></>
                        }
                    </Form.Group>
                </Row>

                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th className="jt-table-check-container jt-no-print"/>
                        <th className="jt-table-center jt-table-id-container"># 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortReservaties("oudCode", "asc")}
                                    color={(sortParamRes["param"] === "oudCode" && sortParamRes["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortReservaties("oudCode", "desc")}
                                    color={(sortParamRes["param"] === "oudCode" && sortParamRes["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th>Speelgoed 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortReservaties("naam", "asc")}
                                    color={(sortParamRes["param"] === "naam" && sortParamRes["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortReservaties("naam", "desc")}
                                    color={(sortParamRes["param"] === "naam" && sortParamRes["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th className={returnList ? "jt-no-print" : ""}>Gebruiker 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortReservaties("gebruiker", "asc")}
                                    color={(sortParamRes["param"] === "gebruiker" && sortParamRes["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortReservaties("gebruiker", "desc")}
                                    color={(sortParamRes["param"] === "gebruiker" && sortParamRes["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th className={returnList ? "jt-no-print" : ""}>Periode</th>
                        {/* <th className={returnList ? "jt-no-print" : ""}>Bevestigd</th>
                        <th className={returnList ? "jt-no-print" : ""}>Archief</th> */}
                        <th className="jt-no-print"/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="jt-no-print">
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={() => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={4}/>
                        <td className="jt-table-actions">
                            <Button variant="success" className="rounded-circle" onClick={() => addReservatieLocal()}><FaPlus/></Button>
                            {actionInProgress === "Bevestigen" && actionId === -1
                                ?
                                <Button variant="primary" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="primary" className="rounded-circle" onClick={() => confirmReservatieRemote(-1, 1)}><FaCheck/></Button>
                            }
                            {actionInProgress === "Uitlenen" && actionId === -1
                                ?
                                <Button variant="secondary" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="secondary" className="rounded-circle" onClick={() => uitleenSpeelgoedRemote(-1, 1)}><FaArrowRight/></Button>
                            }
                            {actionInProgress === "Archiveren" && actionId === -1
                                ?
                                <Button variant="archive" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="archive" className="rounded-circle" onClick={() => archiveReservatieRemote(-1, 1)}><FaArchive/></Button>
                            }
                            <Button variant="danger" className="rounded-circle" onClick={() => deleteReservatieLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {filteredReservaties
                        .filter(item => (
                            (filterPeriode === -1 || item['periodeId'] === filterPeriode)
                            && (filterGebruiker === -1 || item['gebruikerId'] === filterGebruiker)
                            && (filterBevestigd === -1 || item['bevestigd'] === filterBevestigd)
                            && (!returnList || !filteredReservaties
                                .filter(res => (res['speelgoedId'] === item['speelgoedId'] && res['gebruikerId'] === item['gebruikerId']))
                                    .map(res => res['periodeId'])
                                    .includes(returnPeriode)
                            )
                        ))
                        .map((reservatie) => (
                        <tr>
                            <td className="jt-table-id jt-no-print">
                                <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                    <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="jt-table-check"
                                            onChange={() => toggleReservatieChecked(reservatie['id'])}
                                            checked={reservatiesChecked.includes(reservatie['id'])}
                                        />
                                    </Form>
                                </div>
                            </td>
                            <td className="jt-table-id">
                                {speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['oudCode']}
                            </td>
                            <td>
                                <Link to={"/speelgoedfiche?id=" + reservatie['speelgoedId']}>
                                    <img
                                        src={speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['foto']
                                        ? speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['foto']
                                        : "/images/placeholder.jpg"}
                                        className="jt-table-img"
                                        alt=""
                                    />
                                    <span className="jt-table-naam">
                                        {speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['naam']}
                                    </span>
                                </Link>
                                {speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['isArchief']
                                    ?
                                    <Badge pill variant="archive" className="mr-2">
                                        <span>
                                            Gearchiveerd
                                        </span>
                                    </Badge>
                                    :
                                    <></>
                                }
                            </td>
                            <td className={returnList ? "jt-no-print" : ""}>
                                {gebruikers.find(gebruiker => gebruiker['id'] === reservatie['gebruikerId'])['naam']}
                            </td>
                            <td className={returnList ? "jt-no-print" : ""}>
                                {periodes.find(periode => (periode['id'] === reservatie['periodeId']))
                                    ?
                                    <>
                                        <div>
                                            {"Van " + formatDate(periodes.find(periode => (periode['id'] === reservatie['periodeId']))['startDatum'])}
                                        </div>
                                        <div>
                                            {"Tot " + formatDate(periodes.find(periode => (periode['id'] === reservatie['periodeId']))['eindDatum'])}
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </td>
                            {/* <td className={returnList ? "jt-no-print" : ""}>
                                {reservatie['bevestigd']
                                    ?
                                    <Badge pill variant="primary">
                                        <span className="mr-2">
                                            Bevestigd
                                        </span>
                                        <FaTimes as={Button}
                                                 onClick={() => confirmReservatieRemote(reservatie['id'], 0)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <Badge pill variant="danger">
                                        <span className="mr-2">
                                            Niet bevestigd
                                        </span>
                                        <FaTimes as={Button}
                                                 onClick={() => confirmReservatieRemote(reservatie['id'], 1)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                }
                            </td>
                            <td className={returnList ? "jt-no-print" : ""}>
                                {reservatie['isArchief']
                                    ?
                                    <Badge pill variant="archive" className="mr-2">
                                        <span className="mr-2">
                                            {reservatie['archiefDatum']}
                                        </span>
                                        <FaTimes as={Button}
                                                 onClick={() => archiveReservatieRemote(reservatie['id'], 0)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                }
                            </td> */}
                            <td className="jt-table-actions jt-no-print">
                                <Button variant="info" className="rounded-circle" onClick={() => editReservatieLocal(reservatie['id'], 1)}><FaPen/></Button>
                                {actionInProgress === "Bevestigen" && actionId === reservatie['id']
                                    ?
                                    <Button variant="primary" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant={reservatie['bevestigd'] ? "primary" : "outline-primary"} className="rounded-circle" onClick={() => confirmReservatieRemote(reservatie['id'], !reservatie['bevestigd'])}><FaCheck/></Button>
                                }
                                {actionInProgress === "Uitlenen" && actionId === reservatie['id']
                                    ?
                                    <Button variant="secondary" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant={speelgoed.find(s => s['id'] === reservatie['speelgoedId'])['uitgeleend'] ? "secondary" : "outline-secondary"} className="rounded-circle" onClick={() => uitleenSpeelgoedRemote(reservatie['id'], !speelgoed.find(s => s['id'] === reservatie['speelgoedId'])['uitgeleend'])}><FaArrowRight/></Button>
                                }
                                {actionInProgress === "Archiveren" && actionId === reservatie['id']
                                    ?
                                    <Button variant="archive" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant={reservatie['isArchief'] ? "archive" : "outline-archive"} className="rounded-circle" onClick={() => archiveReservatieRemote(reservatie['id'], !reservatie['isArchief'])}><FaArchive/></Button>
                                }
                                <Button variant="danger" className="rounded-circle" onClick={() => deleteReservatieLocal(reservatie['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={showAddModal} onHide={() => onHideModalAdd()}>
                <Modal.Header closeButton>
                    <Modal.Title>Nieuwe reservatie:</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addReservatieRemote}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Speelgoed:</Form.Label>
                            <SearchSelect
                                selectList={speelgoed.filter(item => !item['isArchief'])}
                                onSelect={(id) => setToAddReservatie({...toAddReservatie, "speelgoedId": id})}
                                placeholder={"Speelgoed"}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Gebruiker:</Form.Label>
                            <SearchSelect
                                selectList={gebruikers.filter(item => (item['doelgroepId'] === userData['sessDoelgroep'] && !item['isArchief']))}
                                onSelect={(id) => setToAddReservatie({...toAddReservatie, "gebruikerId": id})}
                                placeholder={"Gebruiker"}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Periode:</Form.Label>
                            <Form.Control
                                id="periode"
                                as="select"
                                value={toAddReservatie['periodeId']}
                                onChange={e => setToAddReservatie({...toAddReservatie, "periodeId": parseInt(e.target.value)})}
                                required
                            >
                                <option value="-1">Kies...</option>
                                {periodes
                                    .filter(item => (!item['isArchief']) || filters['showArchief']).length > 0
                                    ? periodes
                                        .filter(item => (!item['isArchief'] || filters['showArchief']))
                                        .map((periode) => (
                                                <option
                                                    value={periode['id']}
                                                >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                            )
                                        )
                                    : <option value="-1">Geen periodes beschikbaar</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModalAdd()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Maak aan"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Maak aan
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditModal} onHide={() => onHideModalEdit()}>
                <Modal.Header closeButton>
                    <Modal.Title>Bewerk reservatie:</Modal.Title>
                </Modal.Header>
                <Form onSubmit={editReservatieRemote}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Speelgoed:</Form.Label>
                            <SearchSelect
                                selectList={speelgoed.filter(item => !item['isArchief'])}
                                onSelect={(id) => setToEditReservatie({...toEditReservatie, "speelgoedId": id})}
                                placeholder={"Speelgoed"}
                                initialValue={
                                    speelgoed.find(item => (toEditReservatie['speelgoedId'] == item['id']))
                                        ? speelgoed.find(item => (toEditReservatie['speelgoedId'] == item['id']))['naam']
                                        : ""
                                }
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Gebruiker:</Form.Label>
                            <SearchSelect
                                selectList={gebruikers.filter(item => (item['doelgroepId'] == userData['sessDoelgroep'] && !item['isArchief']))}
                                onSelect={(id) => setToEditReservatie({...toEditReservatie, "gebruikerId": id})}
                                placeholder={"Gebruiker"}
                                initialValue={
                                    gebruikers.find(item => (toEditReservatie['gebruikerId'] == item['id']))
                                        ? gebruikers.find(item => (toEditReservatie['gebruikerId'] == item['id']))['naam']
                                        : ""
                                }
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Periode:</Form.Label>
                            <Form.Control
                                as="select"
                                value={toEditReservatie['periodeId']}
                                onChange={e => setToEditReservatie({...toEditReservatie, "periodeId": parseInt(e.target.value)})}
                                defaultValue={toEditReservatie['periodeId']}
                                required
                            >
                                {periodes
                                    .filter(item => (!item['isArchief']) || filters['showArchief']).length > 0
                                    ? periodes
                                        .filter(item => (!item['isArchief'] || filters['showArchief']))
                                        .map((periode) => (
                                                <option
                                                    value={periode['id']}
                                                >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                            )
                                        )
                                    : <option value="-1">Geen periodes beschikbaar</option>
                                }
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModalEdit()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Bewerk"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Bewerk
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder reservatie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} reservatie{toDelete.length > 1 ? "s" : ""} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deleteReservatieRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}