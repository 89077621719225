import {useEffect, useState} from "react";
import {Link, Redirect, useLocation} from "react-router-dom";
import {Button, Row, Col, ListGroup, NavLink, Navbar, Form, Card, Spinner} from 'react-bootstrap';
import Loading from "../components/Loading";
import {FaPen} from "react-icons/fa";
import Review from "../components/Review";
import ReactStars from "react-rating-stars-component";
import SpeelgoedToegevoegd from "../components/SpeelgoedToegevoegd";

export default function SpeelgoedFichePage({isLoggedIn, speelgoed, leeftijd, categorieen, userData, reviews,
                                               gebruikers, periodes, onAddReview, reservaties, onWinkelWagenAdd,
                                               winkelWagen, formatDate}) {
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [speelgoedItem, setSpeelgoedItem] = useState({});

    const [reservatiePeriode, setReservatiePeriode] = useState(-1);

    const [reviewToAdd, setReviewToAdd] = useState({"waardering": 0});

    const [showToegevoegd, setShowToegevoegd] = useState(false);
    const [toegevoedSpeelgoed, setToegevoegdSpeelgoed] = useState({});
    const [toegevoegdPeriode, setToegevoegdPeriode] = useState({"startDatum": "1995-07-13", "eindDatum": "1995-07-13"});

    const [actionInProgress, setActionInProgress] = useState('');

    useEffect(() => {
        let query = location.search;
        let variables = {};
        if (query) {
            query = query.slice(1,query.length); // Remove questionmark
            let variableStrings = query.split('&'); // Split variables
            variableStrings.forEach((variable)=>{
                variables[variable.split('=')[0]] = variable.split('=')[1]; // Split key and value and store in array
            })
            setSpeelgoedItem(speelgoed.find(item => (item['id'] == variables['id'])));
            setLoading(false);
        }
    }, []);

    function ratingChanged(newRating) {
        setReviewToAdd({...reviewToAdd, "waardering": newRating});
    }

    function onShowModalToegevoegd(speelgoedId, periodeId) {
        setToegevoegdSpeelgoed(speelgoed.find(item => (item['id'] === speelgoedId)));
        setToegevoegdPeriode(periodes.find(item => (item['id'] === periodeId)));
        setShowToegevoegd(true);
    }

    function onHideModalToegevoegd() {
        setShowToegevoegd(false);
    }

    function onWinkelWagenAddLocal(e) {
        e.preventDefault();
        if (reservatiePeriode === -1) return alert("Selecteer een periode");
        if (winkelWagen.map(item => item['speelgoedId']).includes(speelgoedItem['id']) && winkelWagen.filter(item => (item['speelgoedId'] === speelgoedItem['speelgoedId'])).map(item => item['periodeId']).includes(reservatiePeriode))
            return alert("Deze reservatie zit reeds in uw winkelwagen");

        setActionInProgress("Reserveren");
        onWinkelWagenAdd(speelgoedItem['id'], reservatiePeriode);
        onShowModalToegevoegd(speelgoedItem['id'], reservatiePeriode);
        setActionInProgress("");
    }

    function jsonEscape(str)  {
        return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t").replace(/"/g, "\\\"");
    }

    async function addReviewRemote(e) {
        e.preventDefault();
        setActionInProgress("Review");

        let today = new Date();
        let todayString = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        const res = await fetch('/php/reviewAdd.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...reviewToAdd, "inhoud": jsonEscape(reviewToAdd['inhoud']), "gebruikerId": userData['id'], "speelgoedId": speelgoedItem['id'], "datum": todayString})
        });
        const data = await res.json();

        if (data['ok']) {
            onAddReview(data['review']);
            setReviewToAdd({"waardering": 0, "inhoud": ""});
            ratingChanged(0);
        } else {
            alert('Het aanmaken van een review is mislukt');
        }
        setActionInProgress("");
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <main className="container position-relative">
                {userData['role'] == "Admin"
                    ?
                    <Navbar className="jt-edit-btn jt-no-print">
                        <NavLink as={Link} to={"/admin/speelgoedfiche?action=bewerken&id=" + speelgoedItem['id']}><FaPen/></NavLink>
                    </Navbar>
                    :
                    <></>
                }
                {/*Titel*/}
                <h1>
                    <span className="mr-2">{speelgoedItem['naam']}</span>
                    <span className="jt-subtitle">
                {
                    (speelgoedItem['oudCode']
                        ? speelgoedItem['oudCode']
                        : "")
                    +
                    (speelgoedItem['merk']
                        ?
                        (speelgoedItem['oudCode']
                            ? ", "
                            : "")
                        +
                        speelgoedItem['merk']
                        : "")
                    +
                    (speelgoedItem['leverancier']
                        ?
                        (speelgoedItem['oudCode'] || speelgoedItem['merk']
                            ? ", "
                            : "")
                        +
                        speelgoedItem['leverancier']
                        : "")
                    +
                    (speelgoedItem['aankoopDatum']
                        ?
                        (speelgoedItem['oudCode'] || speelgoedItem['merk'] || speelgoedItem['leverancier']
                            ? ", "
                            : "")
                        +
                        formatDate(speelgoedItem['aankoopDatum'])
                        : "")

                }
                </span>
                </h1>
                <Row>
                    <Col xs={12} md={4}>
                        <img id="foto" src={speelgoedItem['foto'] ? speelgoedItem['foto'] : "/images/placeholder.jpg"} alt="" className="m-auto"/>
                    </Col>
                    <Col className="col">
                        <Row>
                            {/*Leeftijd*/}
                            <Col xl={6}>
                                <h3>Leeftijd</h3>
                                {speelgoedItem['leeftijden'].length > 0
                                    ?
                                    <ListGroup className="mb-3">
                                        {speelgoedItem['leeftijden'].map(item => (
                                            <ListGroup.Item>{leeftijd['subCategorieen'].find(lt => (lt['id'] == item))['naam']}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    :
                                    <p>Geen leeftijden beschikbaar</p>
                                }
                            </Col>

                            {/*Categorieen*/}
                            <Col xl={6}>
                                <h3>Categorieen</h3>
                                {speelgoedItem['subCategorieen'].length > 0
                                    ?
                                    <ListGroup className="mb-3">
                                        {speelgoedItem['subCategorieen'].map(item => (
                                            <ListGroup.Item>
                                                {categorieen.find(c => (
                                                    c['subCategorieen'].map(sc => sc['id']).includes(item)
                                                ))['subCategorieen'].find(sc => (sc['id'] == item))['naam']}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    :
                                    <p>Geen categorieen beschikbaar</p>
                                }
                            </Col>
                        </Row>

                        <Row>
                            {/*Inhoud*/}
                            <Col xl={6}>
                                <h3>Inhoud</h3>
                                {speelgoedItem['inhoud'].length > 0
                                    ?
                                    <ListGroup className="mb-3">
                                        {speelgoedItem['inhoud'].map(item => (
                                            <ListGroup.Item>
                                                {item['aantal'] + " " + item['text'] + " (" + item['tekort'] + " tekort, " + item['kapot'] + " kapot)"}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    :
                                    <p>Geen inhoud beschikbaar</p>
                                }
                            </Col>

                            {/*Tips*/}
                            <Col xl={6}>
                                <h3>Tips</h3>
                                {speelgoedItem['tips'].length > 0
                                    ?
                                    <ListGroup className="mb-3">
                                        {speelgoedItem['tips'].map(item => (
                                            <ListGroup.Item>
                                                {item['text']}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    :
                                    <p>Geen tips beschikbaar</p>
                                }
                            </Col>
                        </Row>

                        <Row>
                            {/*Korte inhoud*/}
                            <Col xs={12}>
                                <h3>Korte inhoud</h3>
                                <p>
                                    {speelgoedItem['korteInhoud']}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            {/*Bijlages*/}
                            <Col xs={12}>
                                <h3>Bijlages</h3>
                                {speelgoedItem['bijlages'].length > 0
                                    ?
                                    <ul className="list-unstyled">
                                        {speelgoedItem['bijlages'].map(item => (
                                            <li>
                                                <a href={item['path']} target="_blank">{item['type']}</a>
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    <p>Geen bijlages beschikbaar</p>
                                }
                            </Col>
                        </Row>

                        <Form onSubmit={onWinkelWagenAddLocal} className="jt-no-print">
                            <Form.Row className="align-items-center">
                                {speelgoedItem['beschikbaar'] && !speelgoedItem['specialeAanvraag']
                                    ?
                                    <Col xs="auto" className="my-1">
                                        <Form.Label htmlFor="periode" srOnly>
                                            Periode
                                        </Form.Label>
                                        <Form.Control
                                            id="periode"
                                            as="select"
                                            value={reservatiePeriode}
                                            onChange={e => setReservatiePeriode(parseInt(e.target.value))}
                                        >
                                            <option value="-1">Kies...</option>
                                            {periodes.filter(item => !(
                                                item['isArchief'] || reservaties
                                                    .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                                    .map(res => res['periodeId'])
                                                    .includes(item['id']) ||
                                                winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                                    .map(wwItem => wwItem['periodeId'])
                                                    .includes(item['id'])
                                            )).length > 0 ? periodes
                                                .filter(item => !(
                                                    item['isArchief'] || reservaties
                                                        .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                                        .map(res => res['periodeId'])
                                                        .includes(item['id']) ||
                                                    winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                                        .map(wwItem => wwItem['periodeId'])
                                                        .includes(item['id'])
                                                ))
                                                .map((periode) => (
                                                        <option
                                                            value={periode['id']}
                                                        >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                                    )
                                                ) : <option value="-1">Geen periodes beschikbaar</option>}
                                        </Form.Control>
                                    </Col>
                                    :
                                    <></>
                                }
                                <Col xs="auto" className="my-1">
                                    {actionInProgress === "Reserveren"
                                        ?
                                        <Button variant="success" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> Laden...
                                        </Button>
                                        :
                                        <Button
                                            type="submit"
                                            variant={
                                                !speelgoedItem['beschikbaar'] ||
                                                speelgoedItem['specialeAanvraag'] ||
                                                speelgoedItem['isArchief'] ||
                                                periodes
                                                    .filter(item => !(
                                                        item['isArchief'] ||
                                                        reservaties
                                                            .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                                            .map(res => res['periodeId'])
                                                            .includes(item['id']) ||
                                                        winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                                            .map(wwItem => wwItem['periodeId'])
                                                            .includes(item['id'])
                                                    )).length === 0
                                                    ? "danger"
                                                    : "success"}
                                            disabled={
                                                !speelgoedItem['beschikbaar'] ||
                                                speelgoedItem['specialeAanvraag'] ||
                                                speelgoedItem['isArchief'] ||
                                                periodes
                                                    .filter(item => !(
                                                        item['isArchief'] ||
                                                        reservaties
                                                            .filter(res => (!res['isArchief'] && res['speelgoedId'] === speelgoedItem['id']))
                                                            .map(res => res['periodeId'])
                                                            .includes(item['id']) ||
                                                        winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === speelgoedItem['id']))
                                                            .map(wwItem => wwItem['periodeId'])
                                                            .includes(item['id'])
                                                    )).length === 0
                                            }
                                        >
                                            {speelgoedItem['specialeAanvraag'] ? "Speciale aanvraag" : "Reserveren"}
                                        </Button>
                                    }
                                </Col>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>

                <section id="reviews" className="jt-no-print">
                    <h2>Reviews</h2>
                    <Card className="mb-4">
                        <Form onSubmit={addReviewRemote}>
                            <Card.Header>
                                <Card.Title className="mr-4">
                                    Voeg een review toe
                                </Card.Title>
                                <ReactStars
                                    count={5}
                                    size={24}
                                    value={reviewToAdd['waardering']}
                                    onChange={ratingChanged}
                                    activeColor="#ffd700"
                                />
                            </Card.Header>
                            <Card.Body>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Schrijf uw review..."
                                    value={reviewToAdd['inhoud']}
                                    onChange={e => setReviewToAdd({...reviewToAdd, "inhoud": e.target.value})}
                                    required
                                />
                            </Card.Body>
                            <Card.Footer>
                                {actionInProgress === "Review"
                                    ?
                                    <Button variant="success" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Laden...
                                    </Button>
                                    :
                                    <Button variant="success" type="submit">Maak aan</Button>
                                }
                            </Card.Footer>
                        </Form>
                    </Card>
                    {reviews
                        .filter(item => (
                            item['speelgoedId'] === speelgoedItem['id']
                            && (item['gebruikerId'] === userData['id'] || userData['role'] === "Admin")
                            )
                        )
                        .map(review => (
                            <Review
                                review={review}
                                gebruiker={gebruikers.find(item => (item['id'] === review['gebruikerId']))}
                            />
                        ))}
                </section>
            </main>

            <SpeelgoedToegevoegd
                show={showToegevoegd}
                onHide={() => onHideModalToegevoegd()}
                item={toegevoedSpeelgoed}
                periode={toegevoegdPeriode}
                formatDate={formatDate}
            />
        </>
    )
}