import {Form, Button, Row, Col} from "react-bootstrap";
import {useEffect, useState} from "react";
import Filter from '../components/Filter';

export default function Filters({ filters, updateFilters, categorieen, leeftijd, userData, removeCategorieFromFilters }) {
    const [showFilters, setShowFilters] = useState();
    const [showCategorieen, setShowCategorieen] = useState(filters['categorieen'].map(item => item['id']));
    const [showLeeftijd, setShowLeeftijd] = useState(filters['leeftijden'].length > 0);

    function toggleCategorie(id) {
        if (showCategorieen.includes(id)) {
            setShowCategorieen(showCategorieen.filter((item) => !(item === id)));
            removeCategorieFromFilters('categorie', id);
        } else {
            setShowCategorieen([...showCategorieen, id]);
        }
    }

    function toggleLeeftijd(id) {
        if (showLeeftijd) {
            setShowLeeftijd(false);
            removeCategorieFromFilters('leeftijd', id);
        } else {
            setShowLeeftijd(true);
        }
    }

    function initialShow(id) {
        if (leeftijd['id'] === id) {
            setShowLeeftijd(true);
        }
        else {
            setShowCategorieen([...showCategorieen, id]);
        }
    }

    return (
        <div className="rounded p-3 my-3 bg-filter jt-no-print">
            <h2>Filters</h2>
            {/*TODO toggle/collapse button*/}
            <div className="d-flex flex-column">
                <div className="d-flex flex-row flex-wrap mb-2">
                    {leeftijd['id'] !== -1
                    ?
                        <Button
                            variant={(showLeeftijd ? "" : "outline-") + "primary"}
                            onClick={() => toggleLeeftijd(leeftijd['id'])}
                            className="mr-2 mb-2"
                        >
                            Leeftijd
                        </Button>
                    :
                        <></>
                    }
                    {categorieen.map((item) => (
                        <Button
                            variant={(showCategorieen.includes(item['id']) ? "" : "outline-") + "primary"}
                            onClick={() => toggleCategorie(item['id'])}
                            className="mr-2  mb-2"
                        >
                            {item['naam']}
                        </Button>
                    ))}
                </div>
                <div className="d-flex flex-row flex-wrap mb-2">
                    {showLeeftijd
                        ?
                        <Filter
                            categorie={leeftijd}
                            updateFilters={updateFilters}
                            filters={filters}
                            initialShow={initialShow}
                        />
                        : <></>
                    }
                    {categorieen.filter((item) => (showCategorieen.includes(item['id']))).map((item) => (
                        <Filter
                            categorie={item}
                            updateFilters={updateFilters}
                            filters={filters}
                        />
                    ))
                    }
                </div>
            </div>
            {userData['role'] === "Admin"
            ?
                <Row>
                    <Form.Group className="mb-0">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Archief:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={filters['showArchief']}
                                onChange={e => updateFilters('showArchief', parseInt(e.target.value))}
                                required
                            >
                                <option value="-1">Toon alles</option>
                                <option value="0">Verberg archief</option>
                                <option value="1">Toon archief</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-0">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Uitgeleend:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={filters['showUitgeleend']}
                                onChange={e => updateFilters('showUitgeleend', parseInt(e.target.value))}
                                required
                            >
                                <option value="-1">Toon alles</option>
                                <option value="0">Verberg uitgeleend</option>
                                <option value="1">Toon uitgeleend</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Row>
            :
            <></>}
        </div>
    )
}