import {useState} from 'react';
import {Form, Button, Row, Col, Spinner} from "react-bootstrap";
import {sha256} from 'js-sha256';

export default function LoginForm({onLogin, onLoginFailed, doelgroepen}) {
    const [user,setUser] = useState('');
    const [pass,setPass] = useState('');
    const [doelgroep,setDoelgroep] = useState(-1);

    const [actionInProgress, setActionInProgress] = useState('');

    async function onSubmit(e) {
        e.preventDefault();

        //Validate
        if (doelgroep === -1) return alert('Selecteer een doelgroep');

        //Set spinner
        setActionInProgress("Login");

        //Encrypt pass
        const encrpass = sha256(pass);

        const res = await fetch('./php/login.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user, encrpass, doelgroep})
        });
        const data = await res.json();

        if (data['ok']) {
            onLogin(data['userData']);
        } else {
            onLoginFailed(data['errMsg'] ? data['errMsg'] : "Emailadres en wachtwoord combinatie onbekend");
        }

        setUser('');
        setPass('');
        setActionInProgress('');
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={Row} controlId="formBasicUser">
                <Form.Label column lg={3}>Naam of email:</Form.Label>
                <Col lg={9}>
                    <Form.Control
                        type="text"
                        placeholder="Voer naam of email in"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        required
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicPassword">
                <Form.Label column lg={3}>Wachtwoord:</Form.Label>
                <Col lg={9}>
                    <Form.Control
                        type="password"
                        placeholder="Wachtwoord"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        required
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formBasicDoelgroep">
                <Form.Label column lg={3}>Doelgroep:</Form.Label>
                <Col lg={9}>
                    <Form.Control
                        as="select"
                        value={doelgroep}
                        onChange={(e) => setDoelgroep(e.target.value)}
                        required
                    >
                        <option value="-1">Kies...</option>
                        {doelgroepen.length > 0 ? doelgroepen.filter(item => !item['isArchief']).map((doelgroep) => (
                                <option value={doelgroep['id']}>{doelgroep['naam']}</option>
                            )
                        ) : <option value="-1">Geen doelgroepen</option>}
                    </Form.Control>
                </Col>
            </Form.Group>

            {actionInProgress === "Login"
                ?
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> Laden...
                </Button>
                :
                <Button variant="primary" type="submit">
                    Log in
                </Button>
            }
        </Form>
    )
}
