import Filters from "../components/Filters";
import {Badge, Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FaTrashAlt, FaArrowRight} from "react-icons/fa";
import {Link, Redirect} from "react-router-dom";
import {useState} from "react";
import SpeelgoedToegevoegd from "../components/SpeelgoedToegevoegd";

export default function MijnReservatiesPage ({isLoggedIn, userData, filteredReservaties, periodes, speelgoed, filters,
                                        categorieen, leeftijd, updateFilters, removeCategorieFromFilters,
                                        onDeleteReservaties, formatDate, reservaties, winkelWagen, filteredSpeelgoed, onWinkelWagenAdd}) {
    const [allChecked, setAllChecked] = useState(false);
    const [reservatiesChecked, setReservatiesChecked] = useState([]);
    const [filterPeriode, setFilterPeriode] = useState(-1);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    const [showReservatieModal, setShowReservatieModal] = useState(false);
    const [toAddSpeelgoed, setToAddSpeelgoed] = useState({"speelgoedId": -1, "periodeId": -1});
    const [showToegevoegd, setShowToegevoegd] = useState(false);
    const [toegevoedSpeelgoed, setToegevoegdSpeelgoed] = useState({});
    const [toegevoegdPeriode, setToegevoegdPeriode] = useState({"startDatum": "1995-07-13", "eindDatum": "1995-07-13"});

    const [actionInProgress, setActionInProgress] = useState("");

    function toggleAllChecked() {
        if (allChecked) {
            setReservatiesChecked([]);
            setAllChecked(false);
        } else {
            setReservatiesChecked(filteredReservaties
                .filter(item => (
                    (filterPeriode === -1 || item['periodeId'] === filterPeriode) &&
                    (item['gebruikerId'] === userData['id'])))
                .map((reservatie) => (reservatie['id'])));
            setAllChecked(true);
        }
    }

    function toggleReservatieChecked(speelgoedId) {
        setAllChecked(false);
        if (reservatiesChecked.includes(speelgoedId)) {
            setReservatiesChecked(reservatiesChecked.filter((id) => (id !== speelgoedId)))
        } else {
            setReservatiesChecked([...reservatiesChecked, speelgoedId]);
        }
    }

    function deleteReservatieLocal(id) {
        setShowDeleteModal(true);
        let ids = [];
        if (id === -1) {
            ids = reservatiesChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    function reReservatieLocal(id) {
        setShowReservatieModal(true);
        setToAddSpeelgoed({...toAddSpeelgoed, "speelgoedId": id});
    }

    function onWinkelWagenAddLocal(e) {
        e.preventDefault();
        if (!toAddSpeelgoed['periodeId'] || toAddSpeelgoed['periodeId'] === -1) return alert("Selecteer een periode");
        if (winkelWagen.map(item => item['speelgoedId']).includes(toAddSpeelgoed['speelgoedId'] && winkelWagen.filter(item => (item['speelgoedId'] === toAddSpeelgoed['speelgoedId'])).map(item => item['periodeId']).includes(toAddSpeelgoed['periodeId'])))
            return alert("Deze reservatie zit reeds in uw winkelwagen");

        setActionInProgress("Toevoegen");
        onWinkelWagenAdd(toAddSpeelgoed['speelgoedId'], toAddSpeelgoed['periodeId']);
        onShowModalToegevoegd(toAddSpeelgoed['speelgoedId'], toAddSpeelgoed['periodeId']);
        onHideModalReservatie();
        setActionInProgress("");
    }

    function onShowModalToegevoegd(speelgoedId, periodeId) {
        setToegevoegdSpeelgoed(filteredSpeelgoed.find(item => (item['id'] === speelgoedId)));
        setToegevoegdPeriode(periodes.find(item => (item['id'] === periodeId)));
        setShowToegevoegd(true);
    }

    function onHideModalToegevoegd() {
        setShowToegevoegd(false);
    }

    async function deleteReservatieRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/mijnReservatieDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete, "gebruikerId": userData['id']})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeleteReservaties(data['ids']);
        } else {
            alert('Het verwijderen van een reservatie is mislukt');
        }
        onHideModalDelete();
    }

    function onHideModalDelete() {
        setShowDeleteModal(false);
        setToDelete([]);
        setActionInProgress("");
    }

    function onHideModalReservatie() {
        setShowReservatieModal(false);
        setToAddSpeelgoed({...toAddSpeelgoed, "speelgoedId": -1});
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Mijn Reservaties</h1>
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />
                <Form.Group as={Row} className="px-3 py-2">
                    <Col xs="auto" className="d-flex flex-row align-items-center">
                        <Form.Label className="mb-0">Periode:</Form.Label>
                    </Col>
                    <Col xs="auto">
                        <Form.Control
                            as="select"
                            value={filterPeriode}
                            onChange={e => setFilterPeriode(parseInt(e.target.value))}
                            required
                        >
                            <option value="-1">Alle periodes</option>
                            {periodes.filter(item => !item['isArchief']).length > 0 ? periodes.filter(item => !item['isArchief']).map((periode) => (
                                    <option
                                        value={periode['id']}
                                    >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                )
                            ) : <option value="-1">Geen periodes beschikbaar</option>}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th className="jt-table-check-container"/>
                        <th>Speelgoed</th>
                        <th>Periode</th>
                        <th>Bevestigd</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={() => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={3}/>
                        <td className="jt-table-actions">
                            <Button variant="danger" className="rounded-circle" onClick={() => deleteReservatieLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {filteredReservaties
                        .filter(item => (
                                (filterPeriode === -1 || item['periodeId'] === filterPeriode) &&
                                (item['gebruikerId'] === userData['id'])
                            )
                        )
                        .map((reservatie) => (
                        <tr>
                            <td className="jt-table-id">
                                <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                    <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="jt-table-check"
                                            onChange={() => toggleReservatieChecked(reservatie['id'])}
                                            checked={reservatiesChecked.includes(reservatie['id'])}
                                        />
                                    </Form>
                                </div>
                            </td>
                            <td>
                                <Link to={"/speelgoedfiche?id=" + reservatie['speelgoedId']}>
                                    <img
                                        src={speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['foto']
                                            ? speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['foto']
                                            : "/images/placeholder.jpg"}
                                        className="jt-table-img"
                                        alt=""
                                    />
                                    <span className="jt-table-naam">
                                    {speelgoed.find(spg => (reservatie['speelgoedId'] === spg['id']))['naam']}
                                </span>
                                </Link>
                            </td>
                            <td>
                                {periodes.find(periode => (periode['id'] === reservatie['periodeId']))
                                    ?
                                    <>
                                        <div>
                                            {"Van " + formatDate(periodes.find(periode => (periode['id'] === reservatie['periodeId']))['startDatum'])}
                                        </div>
                                        <div>
                                            {"Tot " + formatDate(periodes.find(periode => (periode['id'] === reservatie['periodeId']))['eindDatum'])}
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </td>
                            <td>
                                {reservatie['bevestigd']
                                    ?
                                    <Badge pill variant="primary">
                                        <span>
                                            Bevestigd
                                        </span>
                                    </Badge>
                                    :
                                    <Badge pill variant="danger">
                                        <span>
                                            Niet bevestigd
                                        </span>
                                    </Badge>
                                }
                            </td>
                            <td className="jt-table-actions">
                                <Button variant="primary" className="rounded-circle" onClick={() => reReservatieLocal(reservatie['speelgoedId'])}><FaArrowRight/></Button>
                                <Button variant="danger" className="rounded-circle" onClick={() => deleteReservatieLocal(reservatie['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={showDeleteModal} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder reservatie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} reservatie{toDelete.length > 1 ? "s" : ""} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deleteReservatieRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showReservatieModal} onHide={() => onHideModalReservatie()}>
                <Modal.Header closeButton>
                    <Modal.Title>Opnieuw toevoegen aan winkelwagen</Modal.Title>
                </Modal.Header>
                <Form onSubmit={onWinkelWagenAddLocal}>
                    <Modal.Body>
                        <Form.Group as={Row} className="px-3 py-2">
                            <Col xs="auto" className="d-flex flex-row align-items-center">
                                <Form.Label className="mb-0">Periode:</Form.Label>
                            </Col>
                            <Col xs="auto">
                                <Form.Control
                                    as="select"
                                    value={toAddSpeelgoed['periodeId']}
                                    onChange={e => setToAddSpeelgoed({...toAddSpeelgoed, "periodeId": parseInt(e.target.value)})}
                                    required
                                >
                                    <option value="-1">Kies...</option>
                                    {periodes.filter(item => !(
                                        item['isArchief'] || reservaties
                                            .filter(res => (!res['isArchief'] && res['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                            .map(res => res['periodeId'])
                                            .includes(item['id']) ||
                                        winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                            .map(wwItem => wwItem['periodeId'])
                                            .includes(item['id'])
                                    )).length > 0 ? periodes.filter(item => !(
                                            item['isArchief'] || reservaties
                                                .filter(res => (!res['isArchief'] && res['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                                .map(res => res['periodeId'])
                                                .includes(item['id']) ||
                                            winkelWagen.filter(wwItem => (wwItem['speelgoedId'] === toAddSpeelgoed['speelgoedId']))
                                                .map(wwItem => wwItem['periodeId'])
                                                .includes(item['id'])
                                        ))
                                        .map((periode) => (
                                            <option
                                                value={periode['id']}
                                            >{"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}</option>
                                        )
                                    ) : <option value="-1">Geen periodes beschikbaar</option>}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModalReservatie()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Toevoegen"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Toevoegen
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <SpeelgoedToegevoegd
                show={showToegevoegd}
                onHide={() => onHideModalToegevoegd()}
                item={toegevoedSpeelgoed}
                periode={toegevoegdPeriode}
                formatDate={formatDate}
            />
        </>
    )
}