import Loading from "../../components/Loading";
import {Badge, Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FaArchive, FaPen, FaPlus, FaTimes, FaTrashAlt} from "react-icons/fa";
import {useState} from "react";
import {Redirect} from "react-router-dom";

export default function AdminPeriodesPage({isLoggedIn, periodes, onAddPeriode, onEditPeriode, formatDate, onDeletePeriodes}) {
    const [showArchief, setShowArchief] = useState(0);
    const [addPeriode, setAddPeriode] = useState(false);
    const [currentEditPeriode, setCurrentEditPeriode] = useState(-1);
    const [editedPeriodeStart, setEditedPeriodeStart] = useState('');
    const [editedPeriodeEind, setEditedPeriodeEind] = useState('');
    const [allChecked, setAllChecked] = useState(false);
    const [periodesChecked, setPeriodesChecked] = useState([]);
    const [nieuwePeriodeStart, setNieuwePeriodeStart] = useState('');
    const [nieuwePeriodeEind, setNieuwePeriodeEind] = useState('');

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionId, setActionId] = useState(-1);

    function onHideModal() {
        setAddPeriode(false);
    }

    function onHideModalDelete() {
        setShowDeleteModal(false);
        setToDelete([]);
    }

    function toggleAllChecked() {
        if (allChecked) {
            setPeriodesChecked([]);
            setAllChecked(false);
        } else {
            setPeriodesChecked(periodes
                .filter(item => (showArchief === -1) || (showArchief === 0 && !item['isArchief']) || (showArchief === 1 && item['isArchief']))
                .map(item => item['id'])
            );
            setAllChecked(true);
        }
    }

    function togglePeriodeChecked(periodeId) {
        setAllChecked(false);
        if (periodesChecked.includes(periodeId)) {
            setPeriodesChecked(periodesChecked.filter((id) => (id != periodeId)))
        } else {
            setPeriodesChecked([...periodesChecked, periodeId]);
        }
    }

    function toggleEditedPeriode(periodeId) {
        if (currentEditPeriode == periodeId) {
            setCurrentEditPeriode(-1);
        } else {
            setCurrentEditPeriode(periodeId);
            setEditedPeriodeStart(periodes.find(item => (item['id'] == periodeId))['startDatum']);
            setEditedPeriodeEind(periodes.find(item => (item['id'] == periodeId))['eindDatum']);
        }
    }

    async function addPeriodeRemote(e) {
        e.preventDefault();
        setActionInProgress("Maak aan");

        const res = await fetch('/php/admin/periodeAdd.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({nieuwePeriodeStart, nieuwePeriodeEind})
        });
        const data = await res.json();

        if (data['ok']) {
            onAddPeriode(data['periode']);
        } else {
            alert('Het aanmaken van een periode is mislukt');
        }
        onHideModal();
        setActionInProgress("");
    }

    async function editPeriodeRemote(e) {
        e.preventDefault();
        setActionInProgress("Bewerk");

        const res = await fetch('/php/admin/periodeEdit.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({"periodeId": currentEditPeriode, editedPeriodeStart, editedPeriodeEind, "isArchief": periodes.find(item => (item['id'] == currentEditPeriode))['isArchief']})
        });
        const data = await res.json();

        if (data['ok']) {
            onEditPeriode(data['periode']);
        } else {
            alert('Het bewerken van een periode is mislukt');
        }
        setCurrentEditPeriode(-1);
        setActionInProgress("");
    }

    async function toggleArchivePeriodeRemote(periodeId) {
        setActionId(periodeId);
        setActionInProgress("Archiveren");
        let periode = periodes.find(item => (item['id'] == periodeId));
        const res = await fetch('/php/admin/periodeArchive.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({periodeId, "startDatum": periode['startDatum'], "eindDatum": periode['eindDatum'], "isArchief": !periode['isArchief']})
        });
        const data = await res.json();

        if (data['ok']) {
            onEditPeriode(data['periode']);
        } else {
            alert('Het bewerken van een periode is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    function deletePeriodeLocal(id) {
        setShowDeleteModal(true);
        let ids = [];
        if (id === -1) {
            ids = periodesChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    async function deletePeriodeRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/periodeDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeletePeriodes(data['ids']);
        } else {
            alert('Het verwijderen van een periode is mislukt');
        }
        onHideModalDelete();
        setActionInProgress("");
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Admin Periodes</h1>
                <Row>
                    <Form.Group className="mb-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Label className="mb-0">Archief:</Form.Label>
                        </Col>
                        <Col xs="auto">
                            <Form.Control
                                as="select"
                                value={showArchief}
                                onChange={e => setShowArchief(parseInt(e.target.value))}
                            >
                                <option value="-1">Toon alles</option>
                                <option value="0">Verberg archief</option>
                                <option value="1">Toon archief</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Row>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Start</th>
                        <th>Einde</th>
                        <th>Gearchiveerd</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={(e) => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={3}></td>
                        <td className="jt-table-actions">
                            <Button variant="success" className="rounded-circle" onClick={() => setAddPeriode(!addPeriode)}>{addPeriode ? <FaTimes/> : <FaPlus/>}</Button>
                            <Button variant="danger" className="rounded-circle" onClick={() => deletePeriodeLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {periodes
                        .filter(item => (showArchief === -1) || (showArchief === 0 && !item['isArchief']) || (showArchief === 1 && item['isArchief']))
                        .map(periode => (
                        <tr>
                            {periode['id'] == currentEditPeriode
                                ?
                                <td colSpan={4}>
                                    <div>
                                        <Form onSubmit={e => editPeriodeRemote(e)}>
                                            <Form.Group className="d-flex flex-row my-2">
                                                <Col xs={5}>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Start"
                                                        value={editedPeriodeStart}
                                                        onChange={(e) => setEditedPeriodeStart(e.target.value)}
                                                        required
                                                    />
                                                </Col>
                                                <Col xs={5}>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Einde"
                                                        value={editedPeriodeEind}
                                                        onChange={(e) => setEditedPeriodeEind(e.target.value)}
                                                        required
                                                    />
                                                </Col>
                                                <Col xs={2}>
                                                    {actionInProgress === "Bewerk"
                                                        ?
                                                        <Button variant="primary" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            /> Laden...
                                                        </Button>
                                                        :
                                                        <Button variant="primary" type="submit" className="form-control">
                                                            Bewerk
                                                        </Button>
                                                    }
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </td>
                                :
                                <>
                                    <td className="jt-table-id">
                                        <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                            <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                                <Form.Check
                                                    type="checkbox"
                                                    className="jt-table-check"
                                                    onChange={(e) => togglePeriodeChecked(periode['id'])}
                                                    checked={periodesChecked.includes(periode['id'])}
                                                />
                                            </Form>
                                        </div>
                                    </td>
                                    <td>
                                        {formatDate(periode['startDatum'])}
                                    </td>
                                    <td>
                                        {formatDate(periode['eindDatum'])}
                                    </td>
                                    <td>
                                        {periode['isArchief']
                                            ?
                                            <Badge pill variant="archive" className="mr-2">
                                                <span className="mr-2">
                                                    Gearchiveerd
                                                </span>
                                                <FaTimes as={Button}
                                                         onClick={() => toggleArchivePeriodeRemote(periode['id'])}
                                                         className="jt-clickable"
                                                />
                                            </Badge>
                                            :
                                            <></>
                                        }
                                    </td>
                                </>
                            }
                            <td className="jt-table-actions">
                                <Button variant="info" className="rounded-circle" onClick={() => toggleEditedPeriode(periode['id'])}>{periode['id'] == currentEditPeriode ? <FaTimes/> : <FaPen/>}</Button>
                                {actionInProgress === "Archiveren" && actionId === periode['id']
                                    ?
                                    <Button variant="archive" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant="archive" className="rounded-circle" onClick={() => toggleArchivePeriodeRemote(periode['id'])}><FaArchive/></Button>
                                }
                                <Button variant="danger" className="rounded-circle" onClick={() => deletePeriodeLocal(periode['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={addPeriode} onHide={() => onHideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Nieuwe periode</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addPeriodeRemote}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Startdatum: </Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Startdatum"
                                value={nieuwePeriodeStart}
                                onChange={(e) => setNieuwePeriodeStart(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Einddatum: </Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Einddatum"
                                value={nieuwePeriodeEind}
                                onChange={(e) => setNieuwePeriodeEind(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onHideModal()}>
                            Sluiten
                        </Button>
                        {actionInProgress === "Maak aan"
                            ?
                            <Button variant="success" disabled>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Laden...
                            </Button>
                            :
                            <Button variant="success" type="submit">
                                Maak aan
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder reservatie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} periode{toDelete.length === 1 ? "s" : ""} wilt verwijderen?
                    <br/>
                    De bijbehorende reservaties worden ook verwijderd!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deletePeriodeRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}