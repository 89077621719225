import {Button, Col, Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function SpeelgoedToegevoegd({onHide, item, show, periode, formatDate}){
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Speelgoed toegevoegd</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Link to={"/speelgoedfiche?id=" + item['id']} id={item['id'] + "-naam"}>
                    <Row>
                        <Col xs="2">
                            <img
                                src={item['foto'] ? item['foto'] : "/images/placeholder.jpg"}
                                alt=""
                                className="img-fluid"
                            />
                        </Col>
                        <Col xs="10" className="d-flex flex-row align-items-center">
                            {item['naam']}
                        </Col>
                    </Row>
                </Link>
                <p>
                    {"Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Verder winkelen
                </Button>
                <Button as={Link} to="/winkelwagen" variant="success">
                    Naar winkelwagen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

SpeelgoedToegevoegd.defaultProps = {
    periode: {"startDatum": "1995-07-13", "eindDatum": "1995-07-13"},
    item: {}
}