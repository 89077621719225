import {Redirect, Link, useLocation} from "react-router-dom";
import {Table, Button, Alert, Form, Badge, Modal, Spinner} from 'react-bootstrap'
import {FaPen, FaTrashAlt, FaPlus, FaArchive, FaTimes, FaCaretUp, FaCaretDown, FaArrowRight} from 'react-icons/fa'
import {useEffect, useState} from "react";
import Filters from "../../components/Filters";

export default function AdminSpeelgoedPage({    isLoggedIn,
                                                filteredSpeelgoed,
                                                filters,
                                                updateFilters,
                                                removeCategorieFromFilters,
                                                archiveSpeelgoed,
                                                uitleenSpeelgoed,
                                                onDeleteSpeelgoed,
                                                onToggleBeschikbaar,
                                                onToggleSpecialeAanvraag,
                                                categorieen,
                                                leeftijd,
                                                userData,
                                                sortSpeelgoed, 
                                                sortParam }) {
    const location = useLocation();
    const [action, setAction] = useState();

    const [speelgoedChecked, setSpeelgoedChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    const [actionInProgress, setActionInProgress] = useState('');
    const [actionId, setActionId] = useState(-1);

    useEffect(() => {
        let query = location.search;
        let variables = {};
        if (query) {
            query = query.slice(1,query.length); // Remove questionmark
            let variableStrings = query.split('&'); // Split variables
            variableStrings.forEach((variable)=>{
                variables[variable.split('=')[0]] = variable.split('=')[1]; // Split key and value and store in array
            })
            setAction(variables['action']); // Set action if in variables array
        }
    }, []);

    function toggleAllChecked() {
        if (allChecked) {
            setSpeelgoedChecked([]);
            setAllChecked(false);
        } else {
            setSpeelgoedChecked(filteredSpeelgoed.map((speelgoed) => (speelgoed['id'])));
            setAllChecked(true);
        }
    }

    function toggleSpeelgoedChecked(speelgoedId) {
        setAllChecked(false);
        if (speelgoedChecked.includes(speelgoedId)) {
            setSpeelgoedChecked(speelgoedChecked.filter((id) => (id != speelgoedId)))
        } else {
            setSpeelgoedChecked([...speelgoedChecked, speelgoedId]);
        }
    }

    async function archiveSpeelgoedRemote(id, isArchief) {
        setActionId(id);
        setActionInProgress("Archiveren");
        let ids = [];
        if (id == -1) {
            ids = speelgoedChecked;
        } else {
            ids.push(id);
        }
        let today = new Date();
        let todayString = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        const res = await fetch('/php/admin/speelgoedArchive.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ids, "isArchief": isArchief ? 1 : 0, "archiefDatum": todayString})
        });
        const data = await res.json();

        if (data['ok']) {
            archiveSpeelgoed(data['ids'], data['isArchief'], data['archiefDatum']);
        } else {
            alert('Het arciveren is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    async function uitleenSpeelgoedRemote(id, uitgeleend) {
        setActionId(id);
        setActionInProgress("Uitlenen");
        let ids = [];
        if (id == -1) {
            ids = speelgoedChecked;
        } else {
            ids.push(id);
        }
        const res = await fetch('/php/admin/speelgoedUitlenen.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ids, "uitgeleend": uitgeleend ? 1 : 0})
        });
        const data = await res.json();

        if (data['ok']) {
            uitleenSpeelgoed(data['ids'], data['uitgeleend']);
        } else {
            alert('Het uitlenen is mislukt');
        }
        setActionInProgress("");
        setActionId(-1);
    }

    function deleteSpeelgoedLocal(id) {
        setShowDeleteConfirm(true);
        let ids = [];
        if (id == -1) {
            ids = speelgoedChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    function onHideModal(del) {
        if (del) {
            deleteSpeelgoedRemote();
        }
        setShowDeleteConfirm(false);
    }

    async function deleteSpeelgoedRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/speelgoedDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeleteSpeelgoed(data['ids']);
        } else {
            alert('Het verwijderen is mislukt');
        }
        setActionInProgress("");
    }

    async function toggleBeschikbaarRemote(id, status) {
        const res = await fetch('/php/admin/speelgoedBeschikbaar.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, status})
        });
        const data = await res.json();

        if (data['ok']) {
            onToggleBeschikbaar(data['id'], data['status']);
        } else {
            alert('Het veranderen van status "beschikbaar" is mislukt');
        }
    }

    async function toggleSpecialeAanvraagRemote(id, status) {
        const res = await fetch('/php/admin/speelgoedSpecialeAanvraag.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id, status})
        });
        const data = await res.json();

        if (data['ok']) {
            onToggleSpecialeAanvraag(data['id'], data['status']);
        } else {
            alert('Het veranderen van status "speciale aanvraag" is mislukt');
        }
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container-fluid">
                <h1>Admin Speelgoed</h1>
                <Alert show={action == "toegevoegd"} variant="success" onClose={() => setAction("")} dismissible>
                    <p className="mb-0">Speelgoed succesvol aangemaakt</p>
                </Alert>
                <Alert show={action == "bewerkt"} variant="success" onClose={() => setAction("")} dismissible>
                    <p className="mb-0">Speelgoed succesvol bewerkt</p>
                </Alert>
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th className="jt-table-check-container"></th>
                        <th className="jt-table-center jt-table-id-container"># 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortSpeelgoed("oudCode", "asc")}
                                    color={(sortParam["param"] === "oudCode" && sortParam["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortSpeelgoed("oudCode", "desc")}
                                    color={(sortParam["param"] === "oudCode" && sortParam["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th>Naam 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortSpeelgoed("naam", "asc")}
                                    color={(sortParam["param"] === "naam" && sortParam["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortSpeelgoed("naam", "desc")}
                                    color={(sortParam["param"] === "naam" && sortParam["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={(e) => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan="2"></td>
                        <td className="jt-table-actions">
                            <Button as={Link} to="/admin/speelgoedfiche?action=toevoegen" variant="success" className="rounded-circle"><FaPlus/></Button>
                            {actionInProgress === "Uitlenen" && actionId === -1
                                ?
                                <Button variant="secondary" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="secondary" className="rounded-circle" onClick={() => uitleenSpeelgoedRemote(-1, 1)}><FaArrowRight/></Button>
                            }
                            {actionInProgress === "Archiveren" && actionId === -1
                                ?
                                <Button variant="archive" className="rounded-circle" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </Button>
                                :
                                <Button variant="archive" className="rounded-circle" onClick={() => archiveSpeelgoedRemote(-1, 1)}><FaArchive/></Button>
                            }
                            <Button variant="danger" className="rounded-circle" onClick={() => deleteSpeelgoedLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {filteredSpeelgoed.map((speelgoed) => (
                        <tr>
                            <td className="jt-table-id">
                                <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                    <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                        <Form.Check
                                            type="checkbox"
                                            className="jt-table-check"
                                            onChange={(e) => toggleSpeelgoedChecked(speelgoed['id'])}
                                            checked={speelgoedChecked.includes(speelgoed['id'])}
                                        />
                                    </Form>
                                </div>
                            </td>
                            <td className="jt-table-id">{speelgoed['oudCode']}</td>
                            <td>
                                <Link to={"/speelgoedfiche?id=" + speelgoed['id']} id={speelgoed['id'] + "-naam"}>
                                    <img src={speelgoed['foto'] ? speelgoed['foto'] : "/images/placeholder.jpg"} className="jt-table-img"/>
                                    <span className="jt-table-naam">{speelgoed['naam']}</span>
                                </Link>
                                {/* {speelgoed['isArchief']
                                    ?
                                    <Badge pill variant="archive" className="mr-2">
                                    <span className="mr-2">
                                        {speelgoed['archiefDatum']}
                                    </span>
                                        <FaTimes as={Button}
                                                 onClick={() => archiveSpeelgoedRemote(speelgoed['id'], 0)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                } */}
                                {!speelgoed['beschikbaar']
                                    ?
                                    <Badge pill variant="danger" className="mr-2">
                                    <span className="mr-2">
                                        Niet beschikbaar
                                    </span>
                                        <FaTimes as={Button}
                                                 onClick={() => toggleBeschikbaarRemote(speelgoed['id'], 1)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                }
                                {speelgoed['specialeAanvraag']
                                    ?
                                    <Badge pill variant="primary" className="mr-2">
                                    <span className="mr-2">
                                        Speciale Aanvraag
                                    </span>
                                        <FaTimes as={Button}
                                                 onClick={() => toggleSpecialeAanvraagRemote(speelgoed['id'], 0)}
                                                 className="jt-clickable"
                                        />
                                    </Badge>
                                    :
                                    <></>
                                }
                            </td>
                            <td className="jt-table-actions">
                                <Button as={Link} to={"/admin/speelgoedfiche?action=bewerken&id=" + speelgoed['id']}  variant="info" className="rounded-circle"><FaPen/></Button>
                                {actionInProgress === "Uitlenen" && actionId === speelgoed['id']
                                    ?
                                    <Button variant="secondary" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant={speelgoed['uitgeleend'] ? "secondary" : "outline-secondary"} className="rounded-circle" onClick={() => uitleenSpeelgoedRemote(speelgoed['id'], !speelgoed['uitgeleend'])}><FaArrowRight/></Button>
                                }
                                {actionInProgress === "Archiveren" && actionId === speelgoed['id']
                                    ?
                                    <Button variant="archive" className="rounded-circle" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                    :
                                    <Button variant={speelgoed['isArchief'] ? "archive" : "outline-archive"} className="rounded-circle" onClick={() => archiveSpeelgoedRemote(speelgoed['id'], !speelgoed['isArchief'])}><FaArchive/></Button>
                                }
                                <Button variant="danger" className="rounded-circle" onClick={() => deleteSpeelgoedLocal(speelgoed['id'])}><FaTrashAlt/></Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={showDeleteConfirm} onHide={() => onHideModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Speelgoed verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} stuk{toDelete.length > 1 ? "ken" : ""} speelgoed wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModal(false)}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => onHideModal(true)}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}