import {useState} from 'react';
import {Alert} from "react-bootstrap";

import LoginForm from "../components/LoginForm";
import {Redirect} from "react-router-dom";

export default function LoginPage({onLogin, doelgroepen, isLoggedIn}) {
    const [showAlert, setShowAlert] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    function onLoginFailed(errMsg) {
        setShowAlert(true);
        setErrMsg(errMsg);
    }

    if (isLoggedIn) {
        return (
            <Redirect to="/"/>
        )
    }

    return (

        <main className="container jt-login-container">
            <h1>Login Form</h1>
            <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                <p className="mb-0">{errMsg}</p>
            </Alert>
            <LoginForm onLogin={onLogin} onLoginFailed={onLoginFailed} doelgroepen={doelgroepen}/>
        </main>
    )
}
