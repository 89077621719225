import {Redirect, Link} from "react-router-dom";
import {Table, Form, Row, Col} from 'react-bootstrap'
import {FaCaretUp, FaCaretDown} from 'react-icons/fa'
import {useState, useEffect} from "react";
import Filters from "../../components/Filters";

export default function AdminStatistiekPage({   isLoggedIn,
                                                filteredSpeelgoed,
                                                filters,
                                                updateFilters,
                                                removeCategorieFromFilters,
                                                categorieen,
                                                leeftijd,
                                                userData,
                                                sortSpeelgoed, 
                                                sortParam,
                                                reservaties,
                                                periodes }) {

    const [vanDatum, setVanDatum] = useState('');
    const [totDatum, setTotDatum] = useState('');
    const [statInfo, setStatInfo] = useState('{}');

    const [toonNullen, setToonNullen] = useState('');
    const [sortLocal, setSortLocal] = useState('');

    useEffect(() => {
        let newStatInfo = {};
        filteredSpeelgoed.forEach(item => {
            let count = reservaties.filter(res => res['speelgoedId'] === item['id']).length
            newStatInfo[item['id']] = {'count': count};
        })
        setStatInfo(newStatInfo);
    }, []);

    function updateCount(vanDatum, totDatum) {
        setVanDatum(vanDatum);
        setTotDatum(totDatum);

        if (vanDatum == '' || totDatum == '') {
            let newStatInfo = {};
            filteredSpeelgoed.forEach(item => {
                let count = reservaties.filter(res => res['speelgoedId'] === item['id']).length
                newStatInfo[item['id']] = {'count': count};
            })
            setStatInfo(newStatInfo);
            return;
        };

        let newStatInfo = {};
        filteredSpeelgoed.forEach(item => {
            let count = reservaties.filter(res => (res['speelgoedId'] === item['id'] && periodes.filter(p => (p['startDatum'] >= vanDatum && p['startDatum'] < totDatum)).map(p => p['id']).includes(res['periodeId']))).length
            newStatInfo[item['id']] = {'count': count};
        })
        setStatInfo(newStatInfo);
    }
    
    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container-fluid">
                <h1>Admin Speelgoed</h1>
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />

                <Row className="jt-no-print">
                    {/*Filter periodes*/}
                    <Form.Group className="px-3 py-2">
                        <Col xs="auto">
                            <Form.Label htmlFor="vanDatum">Van: </Form.Label>
                            <Form.Control
                                id="vanDatum"
                                type="date"
                                value={vanDatum}
                                onChange={(e) => updateCount(e.target.value, totDatum)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group className="px-3 py-2">
                        <Col xs="auto">
                            <Form.Label htmlFor="totDatum">Tot: </Form.Label>
                            <Form.Control
                                id="totDatum"
                                type="date"
                                value={totDatum}
                                onChange={(e) => updateCount(vanDatum, e.target.value)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group className="px-3 py-2">
                        <Col xs="auto" className="d-flex flex-row align-items-center">
                            <Form.Check
                                type="switch"
                                id="switch"
                                value={toonNullen}
                                onChange={() => setToonNullen(!toonNullen)}
                                label="Toon speelgoed zonder reservaties"
                            />
                        </Col>
                    </Form.Group>
                </Row>


                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th className="jt-table-center jt-table-id-container"># 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortSpeelgoed("oudCode", "asc")}
                                    color={(sortParam["param"] === "oudCode" && sortParam["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortSpeelgoed("oudCode", "desc")}
                                    color={(sortParam["param"] === "oudCode" && sortParam["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th>Naam 
                            <div className="d-flex flex-row">
                                <FaCaretUp 
                                    onClick={() => sortSpeelgoed("naam", "asc")}
                                    color={(sortParam["param"] === "naam" && sortParam["type"] === "asc") ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => sortSpeelgoed("naam", "desc")}
                                    color={(sortParam["param"] === "naam" && sortParam["type"] === "desc") ? "blue": "black"}
                                />
                            </div>
                        </th>
                        <th className="text-right">Aantal reservaties
                            <div className="d-flex flex-row justify-content-end">
                                <FaCaretUp 
                                    onClick={() => setSortLocal("asc")}
                                    color={sortLocal === "asc" ? "blue": "black"}
                                />
                                <FaCaretDown 
                                    onClick={() => setSortLocal("desc")}
                                    color={sortLocal === "desc" ? "blue": "black"}
                                />
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredSpeelgoed
                        .filter(speelgoed => toonNullen || (statInfo[speelgoed['id']] != undefined ? statInfo[speelgoed['id']]['count'] : 0) != 0)
                        .sort((a, b) => {
                            if (sortLocal === "") return 0;
                            let countA = statInfo[a['id']] != undefined ? statInfo[a['id']]['count'] : 0;
                            let countB = statInfo[b['id']] != undefined ? statInfo[b['id']]['count'] : 0;
                            if (countA < countB) return sortLocal === "asc" ? -1: 1;
                            if (countA > countB) return sortLocal === "asc" ? 1: -1;
                            return 0;
                        })
                        .map((speelgoed) => (
                        <tr>
                            <td className="jt-table-id">{speelgoed['oudCode']}</td>
                            <td>
                                <Link to={"/speelgoedfiche?id=" + speelgoed['id']} id={speelgoed['id'] + "-naam"}>
                                    <img src={speelgoed['foto'] ? speelgoed['foto'] : "/images/placeholder.jpg"} className="jt-table-img"/>
                                    <span className="jt-table-naam">{speelgoed['naam']}</span>
                                </Link>
                            </td>
                            <td className="text-right">{statInfo[speelgoed['id']] != undefined ? statInfo[speelgoed['id']]['count'] : 0}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </main>
        </>
    )
}