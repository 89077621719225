import {Table} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";

export default function WinkelWagenBevestigdPage({isLoggedIn, winkelWagenResult, speelgoed, periodes, formatDate}) {

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <main className="container">
            <h1>Uw reservaties werden verstuurd</h1>
            <section className="mb-5">
                <h2>Gelukte reservaties</h2>
                <p>
                    Deze reservaties werden goed verstuurd, ontvangen en verwerkt.
                </p>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th>Speelgoed</th>
                        <th>Periode</th>
                    </tr>
                    </thead>
                    <tbody>
                    {winkelWagenResult['reservaties'].map(item => (
                        <tr>
                            <td>
                                {speelgoed.filter(spg => (item['speelgoedId'] === spg['id'])).map(spg => (
                                    <Link to={"/speelgoedfiche?id=" + spg['id']} id={spg['id'] + "-naam"}>
                                        <img src={spg['foto'] ? spg['foto'] : "/images/placeholder.jpg"} className="jt-table-img" alt=""/>
                                        <span className="jt-table-naam">{spg['naam']}</span>
                                    </Link>
                                ))}
                            </td>
                            <td>
                                {periodes.filter(periode => (item['periodeId'] === periode['id'])).map(periode => (
                                    "Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </section>

            <section className={winkelWagenResult['doubles'].length > 0 ? "d-block" : "d-none"}>
                <h2>Mislukte reservaties</h2>
                <p>
                    Deze reservaties konden niet worden doorgevoerd wegens overlap met andere reservaties.
                    Mogelijks heeft een andere gebruiker juist iets eerder gereserveerd.
                </p>
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th>Speelgoed</th>
                        <th>Periode</th>
                    </tr>
                    </thead>
                    <tbody>
                    {winkelWagenResult['doubles'].map(item => (
                        <tr>
                            <td>
                                {speelgoed.filter(spg => (item['speelgoedId'] === spg['id'])).map(spg => (
                                    <Link to={"/speelgoedfiche?id=" + spg['id']} id={spg['id'] + "-naam"}>
                                        <img src={spg['foto'] ? spg['foto'] : "/images/placeholder.jpg"} className="jt-table-img" alt=""/>
                                        <span className="jt-table-naam">{spg['naam']}</span>
                                    </Link>
                                ))}
                            </td>
                            <td>
                                {periodes.filter(periode => (item['periodeId'] === periode['id'])).map(periode => (
                                    "Van " + formatDate(periode['startDatum']) + " tot " + formatDate(periode['eindDatum'])
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </section>
        </main>
    )
}