import {Link, Redirect} from "react-router-dom";
import {Button, Form, Modal, Spinner, Table} from "react-bootstrap";
import {FaAngleDoubleDown, FaTimes, FaTrashAlt} from "react-icons/fa";
import {useState} from "react";
import Filters from "../../components/Filters";
import WaarderingsSterren from "../../components/WaarderingsSterren"

export default function AdminReviewPage({isLoggedIn, filters, updateFilters, categorieen,
                                            leeftijd, userData, removeCategorieFromFilters,
                                            filteredSpeelgoed, reviews, onDeleteReview, gebruikers
                                        }) {
    const [allChecked, setAllChecked] = useState(false);
    const [reviewsChecked, setReviewsChecked] = useState([]);

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    const [showInhoud, setShowInhoud] = useState([]);

    const [actionInProgress, setActionInProgress] = useState("");

    function toggleAllChecked() {
        if (allChecked) {
            setReviewsChecked([]);
            setAllChecked(false);
        } else {
            setReviewsChecked(reviews.map((item) => (item['id'])));
            setAllChecked(true);
        }
    }

    function toggleReviewsChecked(reviewId) {
        setAllChecked(false);
        if (reviewsChecked.includes(reviewId)) {
            setReviewsChecked(reviewsChecked.filter((id) => (id !== reviewId)))
        } else {
            setReviewsChecked([...reviewsChecked, reviewId]);
        }
    }

    function toggleReviewInhoud(reviewId) {
        if (showInhoud.includes(reviewId)) {
            setShowInhoud(showInhoud.filter((id) => (id !== reviewId)))
        } else {
            setShowInhoud([...showInhoud, reviewId]);
        }
    }

    function onHideModalDelete() {
        setShowDeleteConfirm(false);
        setActionInProgress("");
    }

    function deleteReviewLocal(id) {
        setShowDeleteConfirm(true);
        let ids = [];
        if (id === -1) {
            ids = reviewsChecked;
        } else {
            ids.push(id);
        }
        setToDelete(ids);
    }

    async function deleteReviewRemote() {
        setActionInProgress("Delete");
        const res = await fetch('/php/admin/reviewDelete.php', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({toDelete})
        });
        const data = await res.json();

        if (data['ok']) {
            onDeleteReview(data['ids']);
        } else {
            alert('Het verwijderen is mislukt');
        }
        onHideModalDelete();
    }

    if (!isLoggedIn) {
        return (
            <Redirect to="/login"/>
        )
    }

    return (
        <>
            <main className="container">
                <h1>Admin Reviews</h1>
                <Filters
                    filters={filters}
                    updateFilters={updateFilters}
                    categorieen={categorieen}
                    leeftijd={leeftijd}
                    userData={userData}
                    removeCategorieFromFilters={removeCategorieFromFilters}
                />
                <Table striped bordered hover size="sm" className="jt-table">
                    <thead>
                    <tr>
                        <th/>
                        <th>Speelgoed</th>
                        <th>Gebruiker</th>
                        <th>Waardering</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="jt-table-id">
                            <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                    <Form.Check
                                        type="checkbox"
                                        className="jt-table-check"
                                        onChange={() => toggleAllChecked()}
                                        checked={allChecked}
                                    />
                                </Form>
                            </div>
                        </td>
                        <td colSpan={3}/>
                        <td className="jt-table-actions">
                            <Button variant="danger" className="rounded-circle" onClick={() => deleteReviewLocal(-1)}><FaTrashAlt/></Button>
                        </td>
                    </tr>
                    {reviews.filter(item => (filteredSpeelgoed.map(speelgoed => speelgoed['id']).includes(item['speelgoedId']))).map(review => (
                        <>
                            <tr>
                                <td className="jt-table-id">
                                    <div className="d-flex justify-content-center align-items-center jt-table-check-container">
                                        <Form className="jt-table-check-container d-flex align-items-center justify-content-center">
                                            <Form.Check
                                                type="checkbox"
                                                className="jt-table-check"
                                                onChange={() => toggleReviewsChecked(review['id'])}
                                                checked={reviewsChecked.includes(review['id'])}
                                            />
                                        </Form>
                                    </div>
                                </td>
                                <td>
                                    <Link to={"/speelgoedfiche?id=" + filteredSpeelgoed.find(item => (item['id'] === review['speelgoedId']))['id']}>
                                        {filteredSpeelgoed.find(item => (item['id'] === review['speelgoedId']))['naam']}
                                    </Link>
                                </td>
                                <td>
                                    {gebruikers.find(item => (item['id'] === review['gebruikerId']))['naam']}
                                </td>
                                <td>
                                    <WaarderingsSterren
                                        waardering={review['waardering']}
                                    />
                                </td>
                                <td className="jt-table-actions">
                                    <Button variant="info" className="rounded-circle" onClick={() => toggleReviewInhoud(review['id'])}>{showInhoud.includes(review['id']) ? <FaTimes/> : <FaAngleDoubleDown/>}</Button>
                                    <Button variant="danger" className="rounded-circle" onClick={() => deleteReviewLocal(review['id'])}><FaTrashAlt/></Button>
                                </td>
                            </tr>
                            {showInhoud.includes(review['id'])
                                ?
                                <tr>
                                    <td colSpan={5} className="bg-filter">
                                        <div className="mx-3 mt-2 mb-1">Inhoud:</div>
                                        <p className="mx-3 mb-2 p-2 bg-achtergrondkleur rounded">
                                            {review['inhoud']}
                                        </p>
                                    </td>
                                </tr>
                                :
                                <></>
                            }
                        </>
                    ))}
                    </tbody>
                </Table>
            </main>

            <Modal show={showDeleteConfirm} onHide={() => onHideModalDelete()}>
                <Modal.Header closeButton>
                    <Modal.Title>Verwijder review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Weet u zeker dat u {toDelete.length} review{toDelete.length === 1 ? "s" : ""} wilt verwijderen?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHideModalDelete()}>
                        Sluiten
                    </Button>
                    {actionInProgress === "Delete"
                        ?
                        <Button variant="danger" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Laden...
                        </Button>
                        :
                        <Button variant="danger" onClick={() => deleteReviewRemote()}>
                            Verwijder
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}